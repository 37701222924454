import React, { useEffect } from "react";
import styled from "styled-components";

const ChargeOrigin = ({ rate, setRate }) => {
  const container = ["containers", "20", "40"];
  const containerSizing = ["20", "40"];
  const looseCargoSizing = ["kg", "lbs"];

  useEffect(() => {
    setRate((rate) => {
      if (!rate?.charges?.find((r) => r.name === "Origin-Charges")) {
        if (rate?.charges === undefined) {
          rate.charges = [];
        }

        rate?.charges?.push({
          name: "Origin-Charges",
          charges: [
            {
              description: "Pickup charge (Origin)",
              currency: rate?.charges?.[0]?.charges?.[0]?.currency || "USD",
              measurement: rate?.charges?.[0]?.charges?.[0]?.measurement
                ? rate?.charges?.[0]?.charges?.[0]?.measurement
                : container.includes(rate.loadType)
                ? containerSizing[0]
                : looseCargoSizing[0],
            },
            {
              description: "Terminal Handling Charges",
              currency: rate?.charges?.[0]?.charges?.[0]?.currency || "USD",
              measurement: rate?.charges?.[0]?.charges?.[0]?.measurement
                ? rate?.charges?.[0]?.charges?.[0]?.measurement
                : container.includes(rate.loadType)
                ? containerSizing[0]
                : looseCargoSizing[0],
            },
            {
              description: "Export charges",
              currency: rate?.charges?.[0]?.charges?.[0]?.currency || "USD",
              measurement: rate?.charges?.[0]?.charges?.[0]?.measurement
                ? rate?.charges?.[0]?.charges?.[0]?.measurement
                : container.includes(rate.loadType)
                ? containerSizing[0]
                : looseCargoSizing[0],
            },
            {
              description: "Ocean freight (freight charge)",
              currency: rate?.charges?.[0]?.charges?.[0]?.currency || "USD",
              measurement: rate?.charges?.[0]?.charges?.[0]?.measurement
                ? rate?.charges?.[0]?.charges?.[0]?.measurement
                : container.includes(rate.loadType)
                ? containerSizing[0]
                : looseCargoSizing[0],
            },
            {
              description: "Import documentation charges",
              currency: rate?.charges?.[0]?.charges?.[0]?.currency || "USD",
              measurement: rate?.charges?.[0]?.charges?.[0]?.measurement
                ? rate?.charges?.[0]?.charges?.[0]?.measurement
                : container.includes(rate.loadType)
                ? containerSizing[0]
                : looseCargoSizing[0],
            },
          ],
        });
      }

      return {
        ...rate,
        charges: rate?.charges,
      };
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setRate((rate) => {
      const chargeIndex = rate?.charges?.findIndex(
        (r) => r.name === "Origin-Charges"
      );

      if (chargeIndex !== -1) {
        rate.charges[chargeIndex].charges = rate?.charges[
          chargeIndex
        ]?.charges.map((r) =>
          container.includes(rate.loadType)
            ? {
                ...r,
                measurement: containerSizing.includes(r.measurement)
                  ? r.measurement
                  : containerSizing[0],
              }
            : {
                ...r,
                measurement: looseCargoSizing.includes(r.measurement)
                  ? r.measurement
                  : looseCargoSizing[0],
              }
        );
      }

      return {
        ...rate,
        charges: rate?.charges,
      };
    });
    // eslint-disable-next-line
  }, [rate.loadType]);

  const onChange = (e, index, key, all) => {
    const { value } = e.target;

    setRate((rate) => {
      const chargeIndex = rate?.charges?.findIndex(
        (r) => r.name === "Origin-Charges"
      );

      if (chargeIndex !== -1) {
        if (all) {
          rate.charges = rate?.charges.map((c) => ({
            ...c,
            charges: c?.charges?.map((r) => ({ ...r, [key]: value })),
          }));
        } else {
          rate.charges[chargeIndex].charges[index] = {
            ...rate.charges[chargeIndex].charges[index],
            [key]: value,
          };
        }
      }

      return {
        ...rate,
        charges: rate?.charges,
      };
    });
  };

  const addMore = () => {
    setRate((rate) => {
      const chargeIndex = rate?.charges?.findIndex(
        (r) => r.name === "Origin-Charges"
      );

      if (chargeIndex !== -1) {
        rate?.charges[chargeIndex]?.charges.push({
          currency: rate?.charges[chargeIndex]?.charges[0].currency,
          measurement: rate?.charges[chargeIndex]?.charges[0].measurement,
          extra: true,
        });
      }

      return {
        ...rate,
        charges: rate?.charges,
      };
    });
  };

  return (
    <>
      <p className="text-lg text-[#16C6A4] font-medium mb-2">Origin charges</p>
      <p className="text-sm mb-4">
        Any fees for transportation documentation or customs clearance
      </p>
      <div className="flex flex-wrap justify-between pb-4">
        {rate?.charges
          ?.find((r) => r.name === "Origin-Charges")
          ?.charges?.map((r, index) => (
            <div key={index} className="w-[45%] shrink-0 mb-4">
              <div className="flex justify-between">
                {r.extra ? (
                  <input
                    type={"text"}
                    onChange={(e) => onChange(e, index, "description")}
                    value={r.description || ""}
                    className="w-full h-full pr-4 bg-none outline-none text-md font-medium mt-1"
                    placeholder="Enter name here"
                  />
                ) : (
                  <p className="font-medium mb-3">{r.description}</p>
                )}
                {containerSizing.includes(r.measurement) && (
                  <select
                    value={r.measurement || ""}
                    onChange={(e) => onChange(e, index, "measurement", true)}
                    className={`appearance-none mb-4 rounded-md outline-0 pr-10 text-sm text-gray-400`}
                  >
                    <option value="20">20ft container</option>
                    <option value="40">40ft container</option>
                  </select>
                )}
                {looseCargoSizing.includes(r.measurement) && (
                  <select
                    value={r.measurement || ""}
                    onChange={(e) => onChange(e, index, "measurement", true)}
                    className={`appearance-none mb-4 rounded-md outline-0 pr-10 text-sm text-gray-400`}
                  >
                    <option value="kg">kg</option>
                    <option value="lbs">lbs</option>
                  </select>
                )}
              </div>
              <div className="border border-[#DFE1E6] flex items-center h-[45px] overflow-hidden border border-[#DFE1E6] rounded-md">
                <div className="w-[30px] h-full flex items-center justify-end text-[#BBB]">
                  $
                </div>
                <input
                  type={"text"}
                  onChange={(e) => onChange(e, index, "amount")}
                  value={r.amount || ""}
                  className="focus:border-[#16C6A4] duration-300 w-full h-full py-3 px-2 bg-none outline-none text-sm"
                  placeholder="0.00"
                />
                <div className="flex items-center">
                  <Flag
                    className={`h-[20px] w-[20px] rounded-full bg-black mr-2 ${r.currency?.toLowerCase()}`}
                  ></Flag>
                  <select
                    className={`appearance-none rounded-md outline-0 pr-10 text-sm text-gray-400 shrink-0`}
                    value={r.currency || ""}
                    onChange={(e) => onChange(e, index, "currency", true)}
                  >
                    <option value="USD">USD</option>
                    <option value="NGN">NGN</option>
                    <option value="GBP">GBP</option>
                    <option value="EUR">EUR</option>
                  </select>
                </div>
              </div>
            </div>
          ))}
      </div>
      <p
        onClick={addMore}
        className="text-[#16C6A4] font-medium text-md mb-10 flex items-center cursor-pointer select-none w-min whitespace-nowrap"
      >
        <span className="material-icons">add</span>Add more charges
      </p>
    </>
  );
};

const Flag = styled.div`
  background-size: cover;
  background-position: center;

  &.usd {
    background-image: url(${require("assets/images/flags/usd.png")});
  }
  &.gbp {
    background-image: url(${require("assets/images/flags/gbp.png")});
  }
  &.eur {
    background-image: url(${require("assets/images/flags/eur.png")});
  }
  &.ngn {
    background-image: url(${require("assets/images/flags/ngn.png")});
  }
`;

export default ChargeOrigin;
