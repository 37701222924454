import { buildQuery, currentENVData } from "../helpers";
import { authService } from "services";

export const rateService = {
  getRates,
  searchPorts,
  searchProducts,
  createRate,
};

function getRates(options) {
  const requestOptions = {
    method: "GET",
  };

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/rate${
    options ? `?${buildQuery(options)}` : ""
  }`;

  return authService
    .apiGate(url, requestOptions)
    .then((analytics) => analytics?.data);
}

function searchPorts(search, page) {
  const requestOptions = {
    method: "GET",
  };

  const params = buildQuery({ search, page, limit: 50 });

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/rate/ports/${
    params ? `?${params}` : ""
  }`;

  return authService.apiGate(url, requestOptions).then((ports) => ports?.data);
}

function searchProducts(search) {
  const requestOptions = {
    method: "GET",
  };

  const params = buildQuery({ search, limit: 50 });

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/rate/products/${
    params ? `?${params}` : ""
  }`;

  return authService
    .apiGate(url, requestOptions)
    .then((products) => products?.data);
}

function createRate(rate) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(rate),
  };

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/rate/admin`;

  return authService.apiGate(url, requestOptions).then((rate) => rate);
}
