import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";

import "styles/fonts/material_icons/font.css";
import { Toaster } from "react-hot-toast";
import Dashboard from "components/Dashboard";
import { PublicRoutes } from "components/common/PublicRoutes";
import routes from "routes";

function App() {
  const authRoutes = ["/enter", "/register", "/forgot-password"];

  const getRoutes = (routes, parent) => {
    return routes
      .filter((r) => (parent ? r.parent === parent : !r.parent))
      .map((route, routeKey) => {
        let routesArray = [];

        if (route.children) {
          routesArray = route.children.map((child, key) => {
            return (
              <Route
                key={`${routeKey}_${key}`}
                path={child.path}
                element={child.component}
              />
            );
          });
        }

        if (route.path && route.children) {
          routesArray.push(
            <Route key={routeKey} path={route.path} element={route.component} />
          );
        }

        if (route.children) {
          return routesArray;
        } else {
          const isAuthPage = authRoutes.includes(route.path);
          
          return (
            <Route
              key={routeKey}
              path={route.path}
              element={
                isAuthPage ? (
                  <PublicRoutes>{route.component}</PublicRoutes>
                ) : (
                  route.component
                )
              }
            />
          );
        }
      });
  };

  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="dashboard" element={<Dashboard />}>
          {getRoutes(routes)}
        </Route>
      </Routes>
    </>
  );
}

export default App;
