import React, { useEffect } from "react";
import { ReactComponent as VerificationImage } from "assets/images/verification-failed.svg";
import { authActions } from "actions";
import { useDispatch } from "react-redux";

const VerificationInvalid = ({ email, auth, setModal }) => {
  const dispatch = useDispatch();

  const resendCode = () => {
    if (auth.resendingVerificationCode === false) {
      dispatch(authActions.resendVerificationCode(email));
    }
  };

  useEffect(() => {
    if (auth.resendingVerificationCodeSuccess) {
      setModal(false);
    }
    // eslint-disable-next-line
  }, [auth.resendingVerificationCodeSuccess]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-1/3 p-12 bg-white text-center text-sm font-medium"
    >
      <VerificationImage className="m-auto mb-6" />
      <p className="mb-4">
        Your verification code is Invalid or has it has exceeded its time limit
      </p>
      <p className="mb-8">
        <span
          className={`select-none ${
            auth?.resendingVerificationCode
              ? "text-gray"
              : "text-[#E35328] underline cursor-pointer"
          }`}
          onClick={resendCode}
        >
          Resend{auth?.resendingVerificationCode && "ing"}
        </span>{" "}
        to get another code
      </p>
    </div>
  );
};

export default VerificationInvalid;
