import React, { useEffect, useState } from "react";
import { ReactComponent as AuthLogo } from "assets/images/logo-with-text.svg";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "actions";
import { useNavigate } from "react-router-dom";
import Loading from "components/common/Loading";
import ModalContainer from "components/common/ModalContainer";
import VerificationInvalid from "./modals/VerificationInvalid";

const Verification = ({ email, setStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [otp, setOtp] = useState("");
  const [modal, setModal] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(authActions.verifyUser(otp));
  };

  const resendCode = () => {
    if (auth.resendingVerificationCode === false) {
      dispatch(authActions.resendVerificationCode(email));
    }
  };

  useEffect(() => {
    if (auth.verifyUserSuccess) {
      navigate("/");
      setStep(0);
    } else if (auth.verifyUserError) {
      setModal("verification-failed");
    } else if (auth.resendingVerificationCodeSuccess) {
      setModal(false);
    }
    // eslint-disable-next-line
  }, [auth]);

  return (
    <>
      {modal !== false ? (
        <ModalContainer closeModal={() => setModal(false)}>
          {modal === "verification-failed" ? (
            <VerificationInvalid
              auth={auth}
              setModal={setModal}
              email={email}
            />
          ) : (
            ""
          )}
        </ModalContainer>
      ) : (
        ""
      )}
      <div className="w-full h-screen flex flex-col no-shrink overflow-y-auto">
        <div className="w-1/3 py-[80px] m-auto">
          <div className="h-[80px] w-full bg-[#16C6A4] rounded-t-lg flex justify-center items-center mb-4">
            <AuthLogo className="h-[30px] w-auto" />
          </div>
          <div className="border border-gray rounded-b-lg p-8 text-center">
            <p className="text-sm mb-6">please enter the verification code</p>
            <OtpInput
              value={otp}
              onChange={setOtp}
              renderInput={(props) => (
                <input className="otp-input" {...props} />
              )}
              numInputs={6}
              inputType={"number"}
              containerStyle="otp-input flex justify-center"
              renderSeparator={<span className={`mx-[3px]`}></span>}
            />
            <p className="text-xs text-[#E35328] my-6">
              Code expires in 2 minutes{" "}
              <span
                className={`select-none ${
                  auth.resendingVerificationCode
                    ? "text-gray"
                    : "text-[#E35328] underline cursor-pointer"
                }`}
                onClick={resendCode}
              >
                [Resend code]
              </span>
            </p>
            <button
              type="button"
              onClick={onSubmit}
              className="bg-[#16C6A4] w-full h-[50px] mt-6 rounded-lg text-white relative"
            >
              {auth.verifyUserLoading ? <Loading /> : "Verify"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
