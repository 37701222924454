import React, { useCallback, useEffect, useRef, useState } from "react";

import Payment from "./Payment";
import { useSelector } from "react-redux";
import ModalContainer from "components/common/ModalContainer";
import PaymentDetails from "./PaymentDetails";
import { usePrevious } from "helpers";
import Loading from "components/common/Loading";

const PaymentList = ({ fetchData }) => {
  const payment = useSelector((state) => state.payment);
  const [isVisible, setIsVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const prevIsVisible = usePrevious(isVisible);

  const observerTarget = useRef(null);

  const callbackFunction = useCallback((entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    if (prevIsVisible === false && isVisible === true) {
      fetchData(payment?.payments?.pagination?.next);
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      threshold: 1,
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line
        observer.unobserve(observerTarget.current);
      }
    };
    // eslint-disable-next-line
  }, [observerTarget]);

  return (
    <>
      {modal !== false ? (
        <ModalContainer closeModal={() => setModal(false)}>
          {modal === "view-details" ? (
            <PaymentDetails
              selectedPayment={
                selectedPayment &&
                payment?.payments?.data?.filter(
                  (q) => q._id === selectedPayment
                )?.[0]
              }
              setModal={setModal}
            />
          ) : (
            ""
          )}
        </ModalContainer>
      ) : (
        ""
      )}
      <div className="p-8">
        <div className="w-full overflow-x-auto pb-20">
          <div className="w-full h-[50px] text-white text-sm px-6 bg-[#16C6A4] rounded-md flex items-center mb-2">
            <div className="w-[10%] shrink-0">Shipment ID</div>
            <div className="w-[25%] shrink-0">Freight forwarder</div>
            <div className="w-[20%] shrink-0">Date of payment</div>
            <div className="w-[20%] shrink-0">Amount</div>
            <div className="w-[10%] shrink-0">Type</div>
            <div className="w-[10%] shrink-0">Status</div>
            <div className="w-[5%] shrink-0"></div>
          </div>
          {payment?.payments?.data ? (
            <>
              {payment?.payments?.data.map((p, index) => (
                <React.Fragment key={index}>
                  <Payment
                    payment={p}
                    setModal={setModal}
                    setSelectedPayment={setSelectedPayment}
                  />
                </React.Fragment>
              ))}
              <div className="relative w-full h-[50px] flex items-center justify-center mt-8">
                {payment?.paymentsLoading && (
                  <Loading size={30} color="black m-auto" />
                )}
              </div>
            </>
          ) : (
            <p className="w-full text-center pt-10">Loading...</p>
          )}
          <div ref={observerTarget}></div>
        </div>
      </div>
    </>
  );
};

export default PaymentList;
