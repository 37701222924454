import React from "react";
import styled from "styled-components";
import LoginImage from "assets/images/login.svg";
import SignupImage from "assets/images/signup.svg";
import { ReactComponent as AuthLogo } from "assets/images/logo-with-text.svg";
import { Link } from "react-router-dom";
import Loading from "components/common/Loading";
import { useSelector } from "react-redux";

const Auth = ({ type, label, subtext, focused, form, onSubmit }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <div className="h-screen w-full bg-white flex">
      <AuthImage
        url={LoginImage}
        className={`bg-center ${
          type === "signup" ? "invisible h-0 w-0" : "h-full w-1/2"
        }`}
      ></AuthImage>
      <AuthImage
        url={SignupImage}
        className={`bg-center ${
          type === "login" ? "invisible h-0 w-0" : "h-full w-1/2"
        }`}
      ></AuthImage>
      <div
        className={`${
          focused ? "w-[52%]" : "w-1/2"
        } duration-100 h-screen relative flex flex-col no-shrink overflow-y-auto`}
      >
        <div className="w-2/3 py-[80px] m-auto">
          <div className="h-[80px] w-full bg-[#16C6A4] rounded-t-lg flex justify-center items-center mb-4">
            <AuthLogo className="h-[30px] w-auto" />
          </div>
          <form
            onSubmit={onSubmit}
            className="border border-gray rounded-b-lg p-8"
          >
            <p className="text-2xl font-semibold text-[#3F3D56] mb-2">
              {label}
            </p>
            <p className="text-sm mb-8">{subtext}</p>
            {form}
            <button
              type="submit"
              className="bg-[#16C6A4] w-full h-[50px] rounded-lg text-white mb-8 relative"
            >
              {(type === "login" && auth.loginLoading) ||
              (type === "signup" && auth.registerLoading) ? (
                <Loading />
              ) : (
                "Submit"
              )}
              {/* Sign In */}
            </button>
            {type === "login" ? (
              <p className="text-[#3F3D56] text-sm font-medium">
                Don't have an Account?{" "}
                <Link to="/signup">
                  <span className="text-[#E35328]">Sign Up</span>
                </Link>
              </p>
            ) : (
              <p className="text-[#3F3D56] text-sm font-medium">
                Already have an Account?{" "}
                <Link to="/">
                  <span className="text-[#E35328]">Login</span>
                </Link>
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const AuthImage = styled.div`
  background-image: url(${(props) => props.url});
`;

export default Auth;
