import { quoteConstants } from "constants";

const initialState = {
  quotes: {},
  quotesLoading: false,
  quotesError: false,
  analytics: {},
  analyticsLoading: false,
  analyticsError: false,
};

export function quote(state = initialState, action) {
  switch (action.type) {
    case quoteConstants.FETCH_QUOTEANALYTICS_REQUEST:
      return {
        ...state,
        analyticsLoading: true,
      };
    case quoteConstants.FETCH_QUOTEANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsLoading: false,
        analytics: action.analytics,
      };
    case quoteConstants.FETCH_QUOTEANALYTICS_ERROR:
      return {
        ...state,
        analyticsLoading: false,
        analyticsError: action.error,
      };
    case quoteConstants.FETCH_QUOTES_REQUEST:
      return {
        ...state,
        quotesLoading: true,
      };
    case quoteConstants.FETCH_QUOTES_SUCCESS: 
      return {
        ...state,
        quotesLoading: false,
        quotes: {
          ...action.quotes,
          data: action.clear ? action.quotes?.data : [...(state.quotes?.data || []), ...action.quotes?.data],
        },
      };
    case quoteConstants.FETCH_QUOTES_ERROR:
      return {
        ...state,
        quotesLoading: false,
        quotesError: action.error,
      };
    default:
      return state;
  }
}
