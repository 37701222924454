import { shipmentConstants } from "constants";

const initialState = {
  shipments: {},
  shipmentsLoading: false,
  shipmentsError: false,
  analytics: {},
  analyticsLoading: false,
  analyticsError: false,
};

export function shipment(state = initialState, action) {
  switch (action.type) {
    case shipmentConstants.FETCH_SHIPMENTANALYTICS_REQUEST:
      return {
        ...state,
        analyticsLoading: true,
      };
    case shipmentConstants.FETCH_SHIPMENTANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsLoading: false,
        analytics: action.analytics,
      };
    case shipmentConstants.FETCH_SHIPMENTANALYTICS_ERROR:
      return {
        ...state,
        analyticsLoading: false,
        analyticsError: action.error,
      };
    case shipmentConstants.FETCH_SHIPMENTS_REQUEST:
      return {
        ...state,
        shipmentsLoading: true,
      };
    case shipmentConstants.FETCH_SHIPMENTS_SUCCESS:
      return {
        ...state,
        shipmentsLoading: false,
        shipments: {
          ...action.shipments,
          data: action.clear ? action.shipments?.data : [...(state.shipments?.data || []), ...action.shipments?.data],
        },
      };
    case shipmentConstants.FETCH_SHIPMENTS_ERROR:
      return {
        ...state,
        shipmentsLoading: false,
        shipmentsError: action.error,
      };
    default:
      return state;
  }
}
