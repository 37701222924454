import { rateService } from "services";
import { rateConstants } from "constants";

function getRates(options, clear) {
  return (dispatch) => {
    function request() {
      return { type: rateConstants.FETCH_RATES_REQUEST };
    }
    function success(rates) {
      return { type: rateConstants.FETCH_RATES_SUCCESS, rates, clear };
    }
    function failure(error) {
      return { type: rateConstants.FETCH_RATES_ERROR, error };
    }

    dispatch(request());

    rateService.getRates(options).then(
      (rates) => {
        dispatch(success(rates));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

function searchPort(source, search, page = 1, clear) {
  return (dispatch) => {
    function request() {
      return { type: rateConstants.SEARCH_PORT_REQUEST, source };
    }
    function success(ports) {
      return { type: rateConstants.SEARCH_PORT_SUCCESS, ports, source, clear };
    }
    function failure(error) {
      return { type: rateConstants.SEARCH_PORT_ERROR, error };
    }

    dispatch(request());

    rateService.searchPorts(search, page).then(
      (rates) => {
        dispatch(success(rates));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

function searchProduct(search, clear) {
  return (dispatch) => {
    function request() {
      return { type: rateConstants.SEARCH_PRODUCTS_REQUEST };
    }
    function success(products) {
      return { type: rateConstants.SEARCH_PRODUCTS_SUCCESS, products, clear };
    }
    function failure(error) {
      return { type: rateConstants.SEARCH_PRODUCTS_ERROR, error };
    }

    dispatch(request());

    rateService.searchProducts(search).then(
      (rates) => {
        dispatch(success(rates));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

function createRate(rate) {
  return (dispatch) => {
    function request() {
      return { type: rateConstants.CREATE_RATE_REQUEST };
    }
    function success(rate) {
      return { type: rateConstants.CREATE_RATE_SUCCESS, rate };
    }
    function failure(error) {
      return { type: rateConstants.CREATE_RATE_ERROR, error };
    }

    dispatch(request());

    rateService.createRate(rate).then(
      (rates) => {
        dispatch(success(rates));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

function clear() {
  return {
    type: rateConstants.CLEAR_RATE,
  };
}

export const rateActions = {
  getRates,
  searchPort,
  searchProduct,
  createRate,
  clear,
};
