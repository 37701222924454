import { downloadURLFile } from "helpers";
import React from "react";
import Moment from "react-moment";
import styled from "styled-components";

const Quote = ({ quote, setSelectedQuote, setModal }) => (
  <div className="w-full text-[#142837] cursor-pointer duration-300 hover:bg-[#ECFAF8] text-sm px-6 py-4 bg-white rounded-md flex mb-2 items-center">
    <div className="w-[10%] shrink-0">
      {quote?.mvxid && `TA${quote?.mvxid}`}
    </div>
    <div className="w-[25%] shrink-0">
      {quote?.shipment?.freightForwarder?.profile?.businessName ||
        quote?.shipment?.freightForwarder?.fullName ||
        "--"}
    </div>
    <div className="w-[15%] shrink-0">
      <Moment format="ddd, DD/MM/YYYY">{quote?.createdAt}</Moment> <br />
      <Moment format="h:mm a">{quote?.createdAt}</Moment>
    </div>
    <div className="w-[15%] shrink-0 capitalize">{quote?.quoteType}</div>
    <div className="w-[15%] shrink-0">
      Aug 16, 2023
      <br />
      12:00 pm
    </div>
    <div className="w-[15%] shrink-0">
      <Status
        className={`text-[#1B2BBC] px-3 p-0.5 max-w-fit rounded-md select-none capitalize font-medium ${quote?.status}`}
      >
        {quote?.status}
      </Status>
    </div>
    <div className="w-[5%] shrink-0 flex justify-center">
      <div className="group h-[50px] relative">
        <div className="h-[40px] w-[40px] rounded flex justify-between items-center select-none cursor-pointer text-[#142837]">
          <span className="material-icons ml-auto">more_horiz</span>
        </div>
        <div className="border border-gray bg-white group-hover:block hidden mt-2 px-4 py-2 text-sm rounded select-none absolute -right-3 z-30">
          {/* <div className="border border-gray bg-white block mt-2 px-4 py-2 text-sm rounded select-none absolute right-0 z-30"> */}
          <div
            onClick={() => {
              setModal("view-details");
              setSelectedQuote(quote._id);
            }}
            className="flex hover:font-medium hover:text-black mt-1 mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap"
          >
            View
          </div>
          <div
            onClick={() => downloadURLFile(quote?.file?.path, quote?.file?.fileName)}
            className="flex hover:font-medium hover:text-black mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap"
          >
            Download
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Status = styled.div`
  &.accepted {
    background: rgba(22, 198, 164, 0.12);
    color: #16c6a4;
  }
  &.pending {
    background: rgba(180, 23, 205, 0.12);
    color: #b417cd;
  }
  &.cancelled {
    background: rgba(227, 83, 40, 0.12);
    color: #e35328;
  }
  &.delivered {
    background: rgba(22, 198, 164, 0.12);
    color: #16c6a4;
  }
`;

export default Quote;
