import { ReactComponent as DashboardIcon } from "assets/images/dashboard-icons/links/dashboard.svg";
import { ReactComponent as DashboardGreenIcon } from "assets/images/dashboard-icons/links/dashboard-green.svg";
import { ReactComponent as ShipmentIcon } from "assets/images/dashboard-icons/links/shipment.svg";
import { ReactComponent as ShipmentGreenIcon } from "assets/images/dashboard-icons/links/shipment-green.svg";
import { ReactComponent as TrackingIcon } from "assets/images/dashboard-icons/links/tracking.svg";
import { ReactComponent as TrackingGreenIcon } from "assets/images/dashboard-icons/links/tracking-green.svg";
import { ReactComponent as QuoteIcon } from "assets/images/dashboard-icons/links/quote.svg";
import { ReactComponent as QuoteGreenIcon } from "assets/images/dashboard-icons/links/quote-green.svg";
import { ReactComponent as PaymentIcon } from "assets/images/dashboard-icons/links/payment.svg";
import { ReactComponent as PaymentGreenIcon } from "assets/images/dashboard-icons/links/payment-green.svg";
import { ReactComponent as FinancialsIcon } from "assets/images/dashboard-icons/links/financials.svg";
import { ReactComponent as FinancialsGreenIcon } from "assets/images/dashboard-icons/links/financials-green.svg";
import { ReactComponent as UserIcon } from "assets/images/dashboard-icons/links/user.svg";
import { ReactComponent as UserGreenIcon } from "assets/images/dashboard-icons/links/user-green.svg";
import { ReactComponent as RateIcon } from "assets/images/dashboard-icons/links/rate.svg";
import { ReactComponent as RateGreenIcon } from "assets/images/dashboard-icons/links/rate-green.svg";
import { ReactComponent as NetPromoterIcon } from "assets/images/dashboard-icons/links/net-promoter.svg";
import { ReactComponent as NetPromoterGreenIcon } from "assets/images/dashboard-icons/links/net-promoter-green.svg";
import { ReactComponent as SettingsIcon } from "assets/images/dashboard-icons/links/settings.svg";
import { ReactComponent as SettingsGreenIcon } from "assets/images/dashboard-icons/links/settings-green.svg";
import { ReactComponent as LogoutIcon } from "assets/images/dashboard-icons/links/logout.svg";
import { ReactComponent as LogoutGreenIcon } from "assets/images/dashboard-icons/links/logout-green.svg";
import DashboardIndex from "components/Dashboard/DashboardIndex";
import Settings from "components/Dashboard/Settings";
import Shipment from "components/Dashboard/Shipment";
import Quote from "components/Dashboard/Quote";
import Payment from "components/Dashboard/Payment";
import User from "components/Dashboard/UserManagement";
import Rate from "components/Dashboard/RateManagement";
import CreateRate from "components/Dashboard/RateManagement/CreateRate";

var routes = [
  {
    path: "index",
    name: "Dashboard",
    component: <DashboardIndex />,
    icon: <DashboardIcon className="h-[18px] w-auto" />,
    iconGreen: <DashboardGreenIcon className="h-[18px] w-auto" />,
  },
  {
    path: "shipment",
    name: "Shipment",
    component: <Shipment />,
    icon: <ShipmentIcon className="h-[18px] w-auto" />,
    iconGreen: <ShipmentGreenIcon className="h-[18px] w-auto" />,
  },
  {
    path: "tracking",
    name: "Tracking",
    component: <DashboardIndex />,
    icon: <TrackingIcon className="h-[22px] w-auto" />,
    iconGreen: <TrackingGreenIcon className="h-[22px] w-auto" />,
    disabled: true,
  },
  {
    path: "quote",
    name: "Quote",
    component: <Quote />,
    icon: <QuoteIcon className="h-[18px] w-auto" />,
    iconGreen: <QuoteGreenIcon className="h-[18px] w-auto" />,
  },
  {
    path: "payment",
    name: "Payment",
    component: <Payment />,
    icon: <PaymentIcon className="h-[14px] w-auto" />,
    iconGreen: <PaymentGreenIcon className="h-[14px] w-auto" />,
  },
  {
    path: "financials",
    name: "Financials",
    component: <DashboardIndex />,
    icon: <FinancialsIcon className="h-[18px] w-auto" />,
    iconGreen: <FinancialsGreenIcon className="h-[18px] w-auto" />,
    disabled: true,
  },
  {
    path: "users",
    name: "User management",
    component: <User />,
    icon: <UserIcon className="h-[18px] w-auto" />,
    iconGreen: <UserGreenIcon className="h-[18px] w-auto" />,
  },
  {
    path: "rates",
    name: "Rate management",
    component: <Rate />,
    icon: <RateIcon className="h-[16px] w-auto" />,
    iconGreen: <RateGreenIcon className="h-[16px] w-auto" />,
    children: [
      {
        path: "rates/create",
        name: "Rate management",
        component: <CreateRate />,
      },
    ],
  },
  {
    path: "nps",
    name: "Net promoter score",
    component: <User />,
    icon: <NetPromoterIcon className="h-[18px] w-auto" />,
    iconGreen: <NetPromoterGreenIcon className="h-[18px] w-auto" />,
    disabled: true,
  },
  {
    path: "settings",
    name: "Settings",
    component: <Settings />,
    icon: <SettingsIcon className="h-[18px] w-auto" />,
    iconGreen: <SettingsGreenIcon className="h-[18px] w-auto" />,
    position: "bottom",
  },
  {
    path: "logout",
    name: "Log out",
    component: <Settings />,
    icon: <LogoutIcon className="h-[18px] w-auto" />,
    iconGreen: <LogoutGreenIcon className="h-[18px] w-auto" />,
    position: "bottom",
  },
];

export default routes;
