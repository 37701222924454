import React from "react";

const ModalContainer = ({ children, closeModal }) => {
  return (
    <div
      onClick={closeModal}
      className="bg-black/50 backdrop-blur-sm fixed left-0 top-0 w-full h-full flex items-center justify-center z-[9000]"
    >
      {children}
    </div>
  );
};

export default ModalContainer;
