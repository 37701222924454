import { paymentService } from "services";
import { paymentConstants } from "constants";

function getAnalytics(options) {
  return (dispatch) => {
    function request() {
      return { type: paymentConstants.FETCH_PAYMENTANALYTICS_REQUEST };
    }
    function success(analytics) {
      return { type: paymentConstants.FETCH_PAYMENTANALYTICS_SUCCESS, analytics };
    }
    function failure(error) {
      return { type: paymentConstants.FETCH_PAYMENTANALYTICS_ERROR, error };
    }

    dispatch(request());

    paymentService.getAnalytics(options).then(
      (analytics) => {
        dispatch(success(analytics));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

function getPayments(options, clear) {
  return (dispatch) => {
    function request() {
      return { type: paymentConstants.FETCH_PAYMENTS_REQUEST };
    }
    function success(payments) {
      return { type: paymentConstants.FETCH_PAYMENTS_SUCCESS, payments, clear };
    }
    function failure(error) {
      return { type: paymentConstants.FETCH_PAYMENTS_ERROR, error };
    }

    dispatch(request());

    paymentService.getPayments(options).then(
      (payments) => {
        dispatch(success(payments));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

export const paymentActions = {
  getAnalytics,
  getPayments,
};
