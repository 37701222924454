import Checkbox from "components/common/Checkbox";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarIcon } from "assets/images/dashboard-icons/calendar.svg";
import moment from "moment";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Additional = ({ rate, setRate }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (!editorState.getCurrentContent().hasText()) {
      setEditorState(
        rate.remarks
          ? EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(rate.remarks))
            )
          : EditorState.createEmpty()
      );
    }
    // eslint-disable-next-line
  }, [rate]);

  useEffect(() => {
    setRate((rate) => {
      if (!startDate) {
        delete rate?.validity?.from;
      } else if (startDate) {
        rate.validity.from = moment(startDate).format("YYYY-MM-DD");
      }

      if (!endDate) {
        delete rate?.validity?.to;
      } else if (endDate) {
        rate.validity.to = moment(endDate).format("YYYY-MM-DD");
      }

      return {
        ...rate,
        validity: {
          ...rate.validity,
        },
      };
    });
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const onEditorChange = (value) => {
    setRate((rate) => ({
      ...rate,
      remarks: draftToHtml(convertToRaw(value.getCurrentContent())),
    }));
  };

  return (
    <>
      <p className="text-lg text-[#16C6A4] font-medium mb-2">
        Additional Services
      </p>
      <p className="text-sm mb-4">
        Kindly provides additional service you would like to ender to this rate
      </p>
      <div className="border-b border-gray pb-10 mb-10">
        <div
          className={`flex w-full rounded-md cursor-pointer items-center justify-between border p-4 px-6 mb-4 ${
            rate?.additionalServices?.customsClearance
              ? "border-black"
              : "border-gray"
          }`}
          onClick={() =>
            setRate((rate) => ({
              ...rate,
              additionalServices: {
                ...rate.additionalServices,
                customsClearance: !rate?.additionalServices?.customsClearance,
              },
            }))
          }
        >
          <div className="w-2/5">
            <p className="text-lg font-medium text-[#142837]">
              Customer clearance
            </p>
          </div>
          <Checkbox checked={rate?.additionalServices?.customsClearance} />
        </div>
        <div
          className={`flex w-full rounded-md cursor-pointer items-center justify-between border p-4 px-6 mb-4 ${
            rate?.additionalServices?.insurance ? "border-black" : "border-gray"
          }`}
          onClick={() =>
            setRate((rate) => ({
              ...rate,
              additionalServices: {
                ...rate.additionalServices,
                insurance: !rate?.additionalServices?.insurance,
              },
            }))
          }
        >
          <div className="w-2/5">
            <p className="text-lg font-medium text-[#142837]">Insurance</p>
          </div>
          <Checkbox checked={rate?.additionalServices?.insurance} />
        </div>
        <div
          className={`flex w-full rounded-md cursor-pointer items-center justify-between border p-4 px-6 mb-4 ${
            rate?.additionalServices?.inspectionService
              ? "border-black"
              : "border-gray"
          }`}
          onClick={() =>
            setRate((rate) => ({
              ...rate,
              additionalServices: {
                ...rate.additionalServices,
                inspectionService: !rate?.additionalServices?.inspectionService,
              },
            }))
          }
        >
          <div className="w-2/5">
            <p className="text-lg font-medium text-[#142837]">
              Inspection service
            </p>
          </div>
          <Checkbox checked={rate?.additionalServices?.inspectionService} />
        </div>
      </div>
      <p className="text-lg text-[#16C6A4] font-medium mb-2">Other details</p>
      <p className="text-sm mb-4">
        Provide any additional information or special request related to your
        rate
      </p>
      <div className="flex justify-between pb-8">
        <div className="w-[45%] shrink-0">
          <p className="font-medium mb-3">Rate validity period</p>
          <div className="h-[45px] px-4 pr-1 flex bg-white border border-gray rounded-md date-picker">
            <div>
              <ReactDatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                withPortal
                placeholderText="Choose date range"
              />
            </div>
            <div className="!w-[45px] h-[45px] flex shrink-0 items-center justify-center">
              <CalendarIcon className="w-[30px] h-auto" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between pb-10">
        <div className="w-full shrink-0">
          <p className="font-medium mb-3">Additional Information</p>
          <div className="w-full">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="wysiwyg-editor"
              onEditorStateChange={(editorState) => {
                setEditorState(editorState);
                onEditorChange(editorState);
              }}
              placeholder="Enter notes or additional information"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Additional;
