import React, { useEffect, useState } from "react";
import PaymentAnalytics from "./Analytics";
import PaymentList from "./List";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { paymentActions } from "actions/paymentActions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as ChartIcon } from "assets/images/dashboard-icons/chart-3.svg";
import { ReactComponent as Check } from "assets/images/dashboard-icons/check.svg";
import { ReactComponent as Select } from "assets/images/dashboard-icons/select-dropdown.svg";
import Loading from "components/common/Loading";
import { currencySign, formatCurrency } from "helpers";

const currencies = ["USD", "NGN", "EUR", "GBP"];

const Payment = () => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.payment);
  const [dateRange, setDateRange] = useState([null, null]);
  const [currency, setCurrency] = useState(0);
  const [showCurrencies, setShowCurrencies] = useState(false);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    dispatch(paymentActions.getAnalytics({ groupBy: "month" }));

    if (payment?.paymentsLoading === false) {
      dispatch(paymentActions.getPayments({}, true));
    }
    // eslint-disable-next-line
  }, []);

  const fetchData = (page) => {
    if (payment?.paymentsLoading === false) {
      dispatch(paymentActions.getPayments({ page }));
    }
  };

  useEffect(() => {
    if (currency !== undefined) {
      setShowCurrencies(false);
    }
  }, [currency]);

  return (
    <>
      <div className="p-8 pb-0 flex justify-between">
        <div className="p-2 bg-white max-w-fit rounded-lg mb-8">
          <div className="h-[35px] bg-[#16C6A4] text-white flex items-center px-8">
            Overview
          </div>
        </div>
        <div className="text-right">
          <p className="text-xs">Today</p>
          <p className="font-bold">
            <Moment format="MMMM DD, YYYY"></Moment>
          </p>
        </div>
      </div>
      <div className="px-8 flex mb-4 relative">
        <div className="h-[40px] w-[40px] rounded-l mr-2 bg-[#16C6A4] flex items-center justify-center">
          <ChartIcon className="h-[10px] w-auto" />
        </div>
        <div className="h-[40px] px-4 pr-6 mr-2 flex bg-white">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            withPortal
            placeholderText="Choose date range"
          />
        </div>
        <div className="h-[40px] w-[40px] rounded-r bg-[#16C6A4] flex items-center justify-center">
          <Check className="h-[15px] w-auto cursor-pointer" />
        </div>
      </div>
      <div className="p-8 pb-0 flex justify-between">
        <div className="p-2 bg-white max-w-fit rounded-lg mb-8">
          <div className="h-[35px] bg-[#16C6A4] text-white flex items-center px-8">
            Gross Transaction Value
          </div>
        </div>
        <div className="text-left flex">
          <div
            onMouseLeave={() => setShowCurrencies(false)}
            className="group h-[50px] relative z-10"
          >
            <div
              onMouseEnter={() => setShowCurrencies(true)}
              className={`h-[40px] w-[100px] mr-6 rounded border border-[#16C6A4] bg-white flex justify-between select-none cursor-pointer text-[#142837]`}
            >
              <div className="pl-4 flex items-center text-sm">
                {currencies[currency]}
              </div>
              <div className="h-[40px] w-[40px] flex items-center justify-center">
                <Select />
              </div>
            </div>
            <div
              className={`${
                showCurrencies ? "block" : "hidden"
              } border border-gray bg-white mt-2 px-4 py-2 text-sm rounded select-none`}
            >
              {currencies
                ?.filter((c, i) => i !== currency)
                .map((c, i) => (
                  <div
                    key={i}
                    onClick={() => setCurrency(currencies.indexOf(c))}
                    className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer"
                  >
                    {c}
                  </div>
                ))}
            </div>
          </div>
          <div className="w-px h-[40px] bg-[#6B778C] mr-6"></div>
          <div>
            <p className="text-xs text-[#E35328]">Pending Pay Out</p>
            <p className="font-bold text-xl">
              {payment.analyticsLoading ? (
                <Loading />
              ) : (
                `${currencySign(currencies[currency])}${
                  payment?.analytics.currencyData?.[currencies[currency]]
                    ?.value?.pendingPayout
                    ? formatCurrency(
                        payment?.analytics.currencyData?.[
                          currencies[currency]
                        ]?.value?.pendingPayout
                      )
                    : "0.00"
                }`
              )}
            </p>
          </div>
        </div>
      </div>
      <PaymentAnalytics />
      <div className="p-8 pb-0 mt-4 flex justify-between">
        <div className="p-2 bg-white max-w-fit rounded-lg">
          <div className="h-[35px] bg-[#16C6A4] text-white flex items-center px-8">
            Payments
          </div>
        </div>
      </div>
      <PaymentList fetchData={fetchData} />
    </>
  );
};

export default Payment;
