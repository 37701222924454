export function currencySign(currency) {
  if (currency?.toLowerCase() === "usd") {
    return "$";
  }
  if (currency?.toLowerCase() === "gbp") {
    return "£";
  }
  if (currency?.toLowerCase() === "eur") {
    return "€";
  }
  if (currency?.toLowerCase() === "ngn") {
    return "₦";
  }
  return currency;
}
