import { userConstants } from "constants";

const initialState = {
  users: {},
  usersLoading: false,
  usersError: false,
  admins: {},
  adminsLoading: false,
  adminsError: false,
};

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.FETCH_USERS_REQUEST:
      return {
        ...state,
        usersLoading: true,
      };
    case userConstants.FETCH_USERS_SUCCESS:
      return {
        ...state,
        usersLoading: false,
        users: {
          ...action.users,
          data: [...(state.users?.data || []), ...action.users?.data],
        },
      };
    case userConstants.FETCH_USERS_ERROR:
      return {
        ...state,
        usersLoading: false,
        usersError: action.error,
      };
    case userConstants.FETCH_ADMINS_REQUEST:
      return {
        ...state,
        adminsLoading: true,
      };
    case userConstants.FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        adminsLoading: false,
        admins: {
          ...action.admins,
          data: [...(state.admins?.data || []), ...action.admins?.data],
        },
      };
    case userConstants.FETCH_ADMINS_ERROR:
      return {
        ...state,
        adminsLoading: false,
        adminsError: action.error,
      };
    default:
      return state;
  }
}
