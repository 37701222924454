import { rateConstants } from "constants";

const initialState = {
  rate: {},
  rates: {},
  ratesLoading: false,
  ratesError: false,
  creatingRate: false,
};

export function rate(state = initialState, action) {
  switch (action.type) {
    case rateConstants.FETCH_RATES_REQUEST:
      return {
        ...state,
        ratesLoading: true,
      };
    case rateConstants.FETCH_RATES_SUCCESS: {
      return {
        ...state,
        ratesLoading: false,
        rates: {
          ...action.rates,
          rates: action.clear
            ? action.rates?.rates
            : [...(state.rates?.rates || []), ...action.rates?.rates],
        },
      };
    }
    case rateConstants.FETCH_RATES_ERROR:
      return {
        ...state,
        ratesLoading: false,
        ratesError: action.error,
      };
    case rateConstants.SEARCH_PORT_REQUEST:
      return {
        ...state,
        [`${action.source}PortsLoading`]: true,
      };
    case rateConstants.SEARCH_PORT_SUCCESS: {
      return {
        ...state,
        [`${action.source}PortsLoading`]: false,
        [`${action.source}Ports`]: action.ports.ports,
      };
    }
    case rateConstants.SEARCH_PORT_ERROR:
      return {
        ...state,
        [`${action.source}PortsLoading`]: false,
        [`${action.source}Error`]: action.error,
      };
    case rateConstants.SEARCH_PRODUCTS_REQUEST:
      return {
        ...state,
        productsLoading: true,
      };
    case rateConstants.SEARCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        productsLoading: false,
        products: action.products.products,
      };
    }
    case rateConstants.SEARCH_PRODUCTS_ERROR:
      return {
        ...state,
        productsLoading: false,
        productsError: action.error,
      };
    case rateConstants.CREATE_RATE_REQUEST:
      return {
        ...state,
        creatingRate: true,
      };
    case rateConstants.CREATE_RATE_SUCCESS: {
      return {
        ...state,
        creatingRate: false,
        rate: action.rate,
      };
    }
    case rateConstants.CREATE_RATE_ERROR:
      return {
        ...state,
        creatingRate: false,
      };
    case rateConstants.CLEAR_RATE:
      return {
        ...state,
        creatingRate: false,
        rate: {},
      };
    default:
      return state;
  }
}
