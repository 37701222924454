import React, { useCallback, useEffect, useRef, useState } from "react";

import User from "./User";
import { useSelector } from "react-redux";
import ModalContainer from "components/common/ModalContainer";
import UserDetails from "./UserDetails";
import { currentENVData, usePrevious } from "helpers";
import Loading from "components/common/Loading";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const UserList = ({ fetchData }) => {
  const user = useSelector((state) => state.user);
  const [isVisible, setIsVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [map, setMap] = useState(null);

  const prevIsVisible = usePrevious(isVisible);

  const observerTarget = useRef(null);

  const callbackFunction = useCallback((entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    if (prevIsVisible === false && isVisible === true) {
      fetchData(user?.users?.pagination?.next);
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      threshold: 1,
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line
        observer.unobserve(observerTarget.current);
      }
    };
    // eslint-disable-next-line
  }, [observerTarget]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: currentENVData.googleAPIKey,
  });

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  return (
    <>
      {modal !== false ? (
        <ModalContainer closeModal={() => setModal(false)}>
          {modal === "view-details" ? (
            <UserDetails
              selectedUser={
                selectedUser &&
                user?.users?.data?.filter((q) => q._id === selectedUser)?.[0]
              }
              setModal={setModal}
            />
          ) : (
            ""
          )}
        </ModalContainer>
      ) : (
        ""
      )}
      {isLoaded ? (
        <GoogleMap center={{ lat: 10, lng: 10 }} zoom={10} onLoad={onLoad}>
          <></>
        </GoogleMap>
      ) : (
        <></>
      )}
      <div className="p-8">
        <div className="w-full overflow-x-auto pb-20">
          <div className="w-full h-[50px] text-white text-sm px-6 bg-[#16C6A4] rounded-md flex items-center mb-2">
            <div className="w-[10%] shrink-0">Business No.</div>
            <div className="w-[25%] shrink-0">Freight forwarder</div>
            <div className="w-[15%] shrink-0">Date joined</div>
            <div className="w-[15%] shrink-0">Status</div>
            <div className="w-[15%] shrink-0">Country</div>
            <div className="w-[15%] shrink-0">Total earnings</div>
            <div className="w-[5%] shrink-0"></div>
          </div>
          {user?.users?.data ? (
            <>
              {user?.users?.data.map((u, index) => (
                <React.Fragment key={index}>
                  <User
                    user={u}
                    map={map}
                    setModal={setModal}
                    setSelectedUser={setSelectedUser}
                  />
                </React.Fragment>
              ))}
              <div className="relative w-full h-[50px] flex items-center justify-center mt-8">
                {user?.usersLoading && (
                  <Loading size={30} color="black m-auto" />
                )}
              </div>
            </>
          ) : (
            <p className="w-full text-center pt-10">Loading...</p>
          )}
          <div ref={observerTarget}></div>
        </div>
      </div>
    </>
  );
};

export default UserList;
