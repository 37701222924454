import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authService } from "services";

export const PublicRoutes = ({ children }) => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth.user);

  if (authService.loggedIn() && accessToken) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.redirect) {
      window.location.href = params.redirect;
    } else {
      navigate("/dashboard/index");
    }
  }
  return children;
};
