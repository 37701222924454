import { buildQuery, currentENVData } from "../helpers";
import { authService } from "services";

export const paymentService = {
  getAnalytics,
  getPayments,
};

function getAnalytics(options) {
  const requestOptions = {
    method: "GET",
  };

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/analytics/invoice/payment${
    options ? `?${buildQuery(options)}` : ""
  }`;

  return authService
    .apiGate(url, requestOptions)
    .then((analytics) => analytics?.data);
}

function getPayments(options) {
  const requestOptions = {
    method: "GET",
  };

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/invoice/filter${
    options ? `?${buildQuery(options)}` : ""
  }`;

  return authService
    .apiGate(url, requestOptions)
    .then((analytics) => analytics?.data);
}
