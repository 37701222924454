import { quoteService } from "services";
import { quoteConstants } from "constants";

function getAnalytics(options) {
  return (dispatch) => {
    function request() {
      return { type: quoteConstants.FETCH_QUOTEANALYTICS_REQUEST };
    }
    function success(analytics) {
      return { type: quoteConstants.FETCH_QUOTEANALYTICS_SUCCESS, analytics };
    }
    function failure(error) {
      return { type: quoteConstants.FETCH_QUOTEANALYTICS_ERROR, error };
    }

    dispatch(request());

    quoteService.getAnalytics(options).then(
      (analytics) => {
        dispatch(success(analytics));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

function getQuotes(options, clear) {
  return (dispatch) => {
    function request() {
      return { type: quoteConstants.FETCH_QUOTES_REQUEST };
    }
    function success(quotes) {
      return { type: quoteConstants.FETCH_QUOTES_SUCCESS, quotes, clear };
    }
    function failure(error) {
      return { type: quoteConstants.FETCH_QUOTES_ERROR, error };
    }

    dispatch(request());

    quoteService.getQuotes(options).then(
      (quotes) => {
        dispatch(success(quotes));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

export const quoteActions = {
  getAnalytics,
  getQuotes,
};
