import { paymentConstants } from "constants";

const initialState = {
  payments: {},
  paymentsLoading: false,
  paymentsError: false,
  analytics: {},
  analyticsLoading: false,
  analyticsError: false,
};

export function payment(state = initialState, action) {
  switch (action.type) {
    case paymentConstants.FETCH_PAYMENTANALYTICS_REQUEST:
      return {
        ...state,
        analyticsLoading: true,
      };
    case paymentConstants.FETCH_PAYMENTANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsLoading: false,
        analytics: action.analytics,
      };
    case paymentConstants.FETCH_PAYMENTANALYTICS_ERROR:
      return {
        ...state,
        analyticsLoading: false,
        analyticsError: action.error,
      };
    case paymentConstants.FETCH_PAYMENTS_REQUEST:
      return {
        ...state,
        paymentsLoading: true,
      };
    case paymentConstants.FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        paymentsLoading: false,
        payments: {
          ...action.payments,
          data: action.clear ? action.payments?.data : [...(state.payments?.data || []), ...action.payments?.data],
        },
      };
    case paymentConstants.FETCH_PAYMENTS_ERROR:
      return {
        ...state,
        paymentsLoading: false,
        paymentsError: action.error,
      };
    default:
      return state;
  }
}
