import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { ReactComponent as Add } from "assets/images/dashboard-icons/add.svg";
import Step1 from "./Step1";
import Step2 from "./Step2";
import ChargeOrigin from "./ChargeOrigin";
import ChargeDestination from "./ChargeDestination";
import ChargeFreight from "./ChargeFreight";
import ChargeDocumentation from "./ChargeDocumentation";
import Additional from "./Additional";
import Review from "./Review";
import ModalContainer from "components/common/ModalContainer";
import PublishRate from "components/Auth/modals/PublishRate";

const CreateRate = () => {
  const [modal, setModal] = useState(false);
  const [rateState, setRateState] = useState({
    additionalServices: {
      customsClearance: false,
      insurance: false,
      inspectionService: false,
    },
  });

  const [step, setStep] = useState(1);

  const rateContainer = useRef(null);

  useEffect(() => {
    rateContainer.current.scrollTo({ top: 0, behavior: "smooth" });
  }, [step]);

  const onChange = (e, key, value, subvalue) => {
    e.stopPropagation();

    if (subvalue === undefined) {
      setRateState((rate) => {
        if (key === "loadType") {
          delete rate.package;
        }

        return {
          ...rate,
          [key]: value,
        };
      });
    } else {
      setRateState((rate) => ({
        ...rate,
        [key]: {
          ...rate[key],
          [value]: subvalue,
        },
      }));
    }
  };

  return (
    <>
      {modal !== false ? (
        <ModalContainer closeModal={() => setModal(false)}>
          {modal === "create-rate" ? (
            <PublishRate rate={rateState} setModal={setModal} />
          ) : (
            ""
          )}
        </ModalContainer>
      ) : (
        ""
      )}
      <div ref={rateContainer} className="h-full overflow-scroll">
        <div className="p-8 pb-0 flex justify-between">
          <div className="p-2 bg-white max-w-fit rounded-lg mb-8">
            <div className="h-[35px] bg-[#16C6A4] text-white flex items-center px-8">
              <Add className="mr-2" />
              Add Rates
            </div>
          </div>
          <div className="text-right">
            <p className="text-xs">Today</p>
            <p className="font-bold">
              <Moment format="MMMM DD, YYYY"></Moment>
            </p>
          </div>
        </div>
        <div className="p-8 pb-0 pt-0 mb-10 flex">
          <div className="bg-white p-6 w-full rounded-md">
            {step === 1 && <Step1 rate={rateState} setRate={setRateState} />}
            {step === 2 && <Step2 rate={rateState} onChange={onChange} />}
            {step === 3 && (
              <ChargeOrigin rate={rateState} setRate={setRateState} />
            )}
            {step === 4 && (
              <ChargeDestination rate={rateState} setRate={setRateState} />
            )}
            {step === 5 && (
              <ChargeFreight rate={rateState} setRate={setRateState} />
            )}
            {step === 6 && (
              <ChargeDocumentation rate={rateState} setRate={setRateState} />
            )}
            {step === 7 && (
              <Additional rate={rateState} setRate={setRateState} />
            )}
            {step === 8 && (
              <Review rate={rateState} setStep={setStep} setModal={setModal} />
            )}
            {step < 8 && (
              <div className="flex justify-between select-none">
                <div className="w-[45%] shrink-0">
                  <button
                    type="button"
                    className="bg-white border border-[#DDD] w-full h-[50px] rounded-md text-[#E35328] relative"
                    onClick={() => {
                      setStep((s) => s - 1);
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className="w-[45%] shrink-0">
                  <button
                    type="button"
                    className="bg-[#16C6A4] border border-[#16C6A4] w-full h-[50px] rounded-md text-white relative"
                    onClick={() => {
                      setStep((s) => s + 1);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRate;
