import { currencySign, formatCurrency } from "helpers";
import React from "react";
import Moment from "react-moment";
import styled from "styled-components";

const Payment = ({ payment, setSelectedShipment, setModal }) => (
  <div className="w-full text-[#142837] cursor-pointer duration-300 hover:bg-[#ECFAF8] text-sm px-6 py-4 bg-white rounded-md flex mb-2 items-center">
    <div className="w-[10%] shrink-0">{`TA${payment?.mvxid}`}</div>
    <div className="w-[25%] shrink-0 pr-2">
      {payment?.freightForwarder?.profile?.businessName ||
        payment?.freightForwarder?.fullName || "--"}
    </div>
    <div className="w-[20%] shrink-0">
      <Moment format="ddd, DD/MM/YYYY">{payment?.paymentDate}</Moment>
    </div>
    <div className="w-[20%] shrink-0 text-[#16C6A4] font-medium">
      {currencySign(payment?.amount?.currency)}
      {formatCurrency(payment?.amount?.value)}
    </div>
    <div className="w-[10%] shrink-0">
      {payment?.invoiceType === "shipment" ? "C2FF" : "FF2FF"}
    </div>
    <div className="w-[10%] shrink-0">
      <Status
        className={`text-[#1B2BBC] px-3 p-0.5 max-w-fit rounded-md select-none capitalize font-medium text-sm ${payment?.paymentStatus}`}
      >
        {payment?.paymentStatus}
      </Status>
    </div>
    <div className="w-[5%] shrink-0 flex justify-center">
      {/* <span className="material-icons">more_horiz</span> */}

      <div className="group h-[50px] relative">
        <div className="h-[40px] w-[40px] rounded flex justify-between items-center select-none cursor-pointer text-[#142837]">
          <span className="material-icons ml-auto">more_horiz</span>
        </div>
        <div className="border border-gray bg-white group-hover:block hidden mt-2 px-4 py-2 text-sm rounded select-none absolute -right-3 z-30">
          {/* <div className="border border-gray bg-white block mt-2 px-4 py-2 text-sm rounded select-none absolute right-0 z-30"> */}
          <div
            onClick={() => {
              setModal("view-details");
              setSelectedShipment(payment._id);
            }}
            className="flex hover:font-medium hover:text-black mt-1 mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap"
          >
            View Details
          </div>
          <div className="flex mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap">
            Share Link
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Status = styled.div`
  &.pending {
    background: rgba(119, 172, 241, 0.12);
    color: #77acf1;
  }
  &.paid {
    background: rgba(22, 198, 164, 0.12);
    color: #16c6a4;
  }
  &.payout-completed {
    background: rgba(227, 83, 40, 0.12);
    color: #e35328;
  }
`;

export default Payment;
