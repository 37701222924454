import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ReactComponent as ChartIcon1 } from "assets/images/dashboard-icons/chart-1.svg";
import { ReactComponent as ChartIcon2 } from "assets/images/dashboard-icons/chart-2.svg";
import { ReactComponent as Select } from "assets/images/dashboard-icons/select-dropdown.svg";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "#77ACF1",
      barPercentage: 0.5,
      categoryPercentage: 0.5,
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "#16C6A4",
      barPercentage: 0.5,
      categoryPercentage: 0.5,
    },
    {
      label: "Dataset 3",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "#F19477",
      barPercentage: 0.5,
      categoryPercentage: 0.5,
    },
  ],
};

const AnalyticsChart = ({ title }) => {
  return (
    <div className="bg-white rounded-md drop-shadow">
      <div className="p-8">
        <div className="flex z-50 relative justify-between mb-4">
          <div className="h-[40px] flex items-center">
            <ChartIcon1 className="h-[20px] w-auto" />
          </div>
          <div className="w-full h-[40px] flex items-center pl-4 select-none text-[#6B778C] font-medium">
            {title}
          </div>
          <div className="flex">
            <div className="h-[40px] w-[40px] rounded mr-4 bg-[#6B778C]/20 flex items-center justify-center">
              <ChartIcon2 className="h-[10px] w-auto" />
            </div>
            <div className="mr-4 group h-[50px]">
              <div className="border border-gray bg-white relative h-[40px] w-[250px] rounded flex justify-between select-none cursor-pointer text-[#142837]">
                <div className="pl-4 flex items-center text-sm">
                  Shipment stat
                </div>
                <div className="h-[40px] w-[40px] flex items-center justify-center">
                  <Select />
                </div>
              </div>
              <div className="border border-gray bg-white group-hover:block hidden mt-2 p-4 rounded select-none">
                <p className="text-sm pb-2 mb-3 border-b border-gray">
                  <span className="text-[#E35328]">Filter by:</span>{" "}
                  <span className="text-[#6B778C]">Shipment stat</span>
                </p>
                <div className="flex text-sm mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                  <div className="border-2 border-[#6B778C] rounded-full flex items-center justify-center p-1 mr-4">
                    <div className="h-[12px] w-[12px] rounded-full bg-[#6B778C]"></div>
                  </div>
                  Shipment Created
                </div>
                <div className="flex text-sm mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                  <div className="border-2 border-[#6B778C] rounded-full flex items-center justify-center p-1 mr-4">
                    <div className="h-[12px] w-[12px] rounded-full bg-[#6B778C]"></div>
                  </div>
                  Shipment Delivered
                </div>
                <div className="flex text-sm mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                  <div className="border-2 border-[#6B778C] rounded-full flex items-center justify-center p-1 mr-4">
                    <div className="h-[12px] w-[12px] rounded-full bg-[#6B778C]"></div>
                  </div>
                  Shipment Cancelled
                </div>
              </div>
            </div>
            <div className="mr-4 group h-[50px]">
              <div className="border border-gray h-[40px] w-[100px] rounded flex justify-between select-none cursor-pointer text-[#142837]">
                <div className="pl-4 flex items-center text-sm">Daily</div>
                <div className="h-[40px] w-[40px] flex items-center justify-center">
                  <Select />
                </div>
              </div>
              <div className="border border-gray bg-white group-hover:block hidden mt-2 px-4 py-2 text-sm rounded select-none">
                <div className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                  Daily
                </div>
                <div className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                  Weekly
                </div>
                <div className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                  Yearly
                </div>
              </div>
            </div>
          </div>
        </div>
        <Bar options={options} data={data} />
      </div>
    </div>
  );
};

export default AnalyticsChart;
