import { shipmentService } from "services";
import { shipmentConstants } from "constants";

function getAnalytics(options) {
  return (dispatch) => {
    function request() {
      return { type: shipmentConstants.FETCH_SHIPMENTANALYTICS_REQUEST };
    }
    function success(analytics) {
      return { type: shipmentConstants.FETCH_SHIPMENTANALYTICS_SUCCESS, analytics };
    }
    function failure(error) {
      return { type: shipmentConstants.FETCH_SHIPMENTANALYTICS_ERROR, error };
    }

    dispatch(request());

    shipmentService.getAnalytics(options).then(
      (analytics) => {
        dispatch(success(analytics));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

function getShipments(options, clear) {
  return (dispatch) => {
    function request() {
      return { type: shipmentConstants.FETCH_SHIPMENTS_REQUEST };
    }
    function success(shipments) {
      return { type: shipmentConstants.FETCH_SHIPMENTS_SUCCESS, shipments, clear };
    }
    function failure(error) {
      return { type: shipmentConstants.FETCH_SHIPMENTS_ERROR, error };
    }

    dispatch(request());

    shipmentService.getShipments(options).then(
      (shipments) => {
        dispatch(success(shipments));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

export const shipmentActions = {
  getAnalytics,
  getShipments,
};
