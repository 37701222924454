import React from "react";
import AnalyticsChart from "../AnalyticsChart";
import Analytics from "components/common/Analytics";
import { ReactComponent as BluegraphIcon } from "assets/images/dashboard-icons/blue-graph.svg";
import { ReactComponent as GreengraphIcon } from "assets/images/dashboard-icons/green-graph.svg";
import { ReactComponent as RedgraphIcon } from "assets/images/dashboard-icons/red-graph.svg";
import { ReactComponent as PurplegraphIcon } from "assets/images/dashboard-icons/purple-graph.svg";
import { ReactComponent as BluepercentageIcon } from "assets/images/dashboard-icons/blue-percentage.svg";
import { ReactComponent as GreenpercentageIcon } from "assets/images/dashboard-icons/green-percentage.svg";
import { ReactComponent as OrangepercentageIcon } from "assets/images/dashboard-icons/orange-percentage.svg";
import { useSelector } from "react-redux";

const QuoteAnalytics = () => {
  const quote = useSelector((state) => state.quote);

  return (
    <>
      <div className="p-8 pb-4 border-b border-[#D9D9D9]">
        <div className="flex flex-wrap [&>*:nth-child(3)]:mr-0">
          <Analytics
            title="No. of Quotes Created by FF"
            loading={quote?.analyticsLoading}
            value={quote?.analytics?.quotesByFF}
            image={<BluegraphIcon />}
          />
          <Analytics
            title="No. of Quote Requests from FF-FF"
            loading={quote?.analyticsLoading}
            value={quote?.analytics?.quotesFFtoFF}
            image={<GreengraphIcon />}
          />
          <Analytics
            title="No. of Instant Quotes Requested"
            loading={quote?.analyticsLoading}
            value={quote?.analytics?.instantQuotesRequested}
            image={<RedgraphIcon />}
          />
          <Analytics
            title="No. of Quote Converted by LFF"
            loading={quote?.analyticsLoading}
            value={quote?.analytics?.quotesConvertedByFF}
            image={<PurplegraphIcon />}
          />
          <Analytics
            title="No. of Instant Quotes Converted to live Shipment"
            loading={quote?.analyticsLoading}
            value="400"
            image={<BluegraphIcon />}
          />
        </div>
      </div>
      <div className="p-8">
        <div className="flex flex-wrap [&>*:nth-child(3)]:mr-0">
          <Analytics
            title="Total Pending Quotes"
            loading={quote?.analyticsLoading}
            value={quote?.analytics?.pendingQuotes?.percentage}
            image={<BluepercentageIcon />}
            sub={{
              title: "Response time",
              value: quote?.analytics?.pendingQuotes?.responseTime,
            }}
          />
          <Analytics
            title="Total Accepted Quotes"
            loading={quote?.analyticsLoading}
            value={quote?.analytics?.acceptedQuotes?.percentage}
            image={<GreenpercentageIcon />}
            sub={{
              title: "Response time",
              value: quote?.analytics?.acceptedQuotes?.responseTime,
            }}
          />
          <Analytics
            title="Total Rejected Quotes"
            loading={quote?.analyticsLoading}
            value={quote?.analytics?.rejectedQuotes?.percentage}
            image={<OrangepercentageIcon />}
            sub={{
              title: "Response time",
              value: quote?.analytics?.rejectedQuotes?.responseTime,
            }}
          />
        </div>
        <AnalyticsChart title="The total no of quotes created" />
      </div>
    </>
  );
};

export default QuoteAnalytics;
