import { dashboardConstants } from "constants";

const initialState = {
  analytics: {},
  analyticsLoading: false,
  analyticsError: false,
};

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.FETCH_CARDANALYTICS_REQUEST:
      return {
        ...state,
        analyticsLoading: true,
      };
    case dashboardConstants.FETCH_CARDANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsLoading: false,
        analytics: action.analytics,
      };
    case dashboardConstants.FETCH_CARDANALYTICS_ERROR:
      return {
        ...state,
        analyticsLoading: false,
        analyticsError: action.error,
      };
    default:
      return state;
  }
}
