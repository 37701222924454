import CustomToast from "components/common/CustomToast";

import toast from "react-hot-toast";
import { authService } from "services";
import { authConstants } from "constants";

export const authActions = {
  login,
  register,
  forgotPassword,
  resetPassword,
  sendVerifyMail,
  verifyUser,
  resendVerificationCode,
  updateUserProfile,
  updateUserProfileImage,
  updateUserPassword,
  deactivateAccount,
};

function login(user) {
  let data = {
    ...user,
  };

  return (dispatch) => {
    dispatch(request());

    authService.login(data).then(
      (user) => {
        dispatch(success(user?.data?.data));
      },
      (error) => {
        if (error.message) {
          toast.error(error.message);

          dispatch(failure(error.message));
        }
      }
    );
  };

  function request() {
    return { type: authConstants.LOGIN_REQUEST };
  }
  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
}

function register(user) {
  let data = {
    ...user,
    mobile:
      user.mobileExtension && user.mobileExtension !== ""
        ? user.mobileExtension.replace(/\s/g, "") + parseInt(user.rawMobile, 10)
        : user.rawMobile,
  };

  return (dispatch) => {
    dispatch(request(data));

    authService.register(data).then(
      (user) => {
        toast.success(user.message);
        dispatch(success(user));
      },
      (error) => {
        if (error.message) {
          toast.error(error.message);

          dispatch(failure(error.message));
        }
      }
    );
  };

  function request(user) {
    return { type: authConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.REGISTER_FAILURE, error };
  }
}

function forgotPassword(email, sendSuccess = false) {
  return (dispatch) => {
    dispatch(request(authConstants.FORGET_PASSWORD_REQUEST));

    authService.forgotPassword(email).then(
      (res) => {
        dispatch(success(authConstants.FORGET_PASSWORD_SUCCESS, res.message));
        sendSuccess &&
          toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(authConstants.FORGET_PASSWORD_ERROR, error.message));
        }
      }
    );
  };
}

function resetPassword(credentials) {
  return (dispatch) => {
    dispatch(request(authConstants.RESET_REQUEST));

    authService.resetPassword(credentials).then(
      (res) => {
        dispatch(success(authConstants.RESET_SUCCESS, res.message));
        toast.custom((t) => <CustomToast t={t} message={res.message} />);
        window.location.href = "/enter";
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(authConstants.RESET_ERROR, error.message));
        }
      }
    );
  };
}

function sendVerifyMail(emailObj) {
  return (dispatch) => {
    dispatch(request(authConstants.SEND_VERIFY_REQUEST));

    authService.sendVerifyMail(emailObj).then(
      (res) => {
        dispatch(success(authConstants.SEND_VERIFY_SUCCESS, res.message));
        toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(authConstants.SEND_VERIFY_ERROR, error.message));
        }
      }
    );
  };
}

function verifyUser(otpObj, showSuccessMessage = true) {
  return (dispatch) => {
    dispatch(request(authConstants.VERIFY_REQUEST));

    authService.verifyUser({ code: otpObj }).then(
      (res) => {
        dispatch(success(authConstants.VERIFY_SUCCESS, res.message));
        showSuccessMessage &&
          toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(authConstants.VERIFY_ERROR, error.message));
        }
      }
    );
  };
}

function resendVerificationCode(email) {
  const emailObj = {
    email,
  };

  return (dispatch) => {
    dispatch(request(authConstants.VERIFICATION_CODE_REQUEST));

    authService.sendVerificationCode(emailObj).then(
      (res) => {
        dispatch(success(authConstants.VERIFICATION_CODE_SUCCESS, res.message));

        toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(
            failure(authConstants.VERIFICATION_CODE_ERROR, error.message)
          );
        }
      }
    );
  };
}

function updateUserProfile(data) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_USER_REQUEST));

    authService.updateUserProfile(data).then(
      (res) => {
        dispatch(success(authConstants.UPDATE_USER_SUCCESS, res));
        toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        // if (error.status !== 404 && error.message) {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
        dispatch(failure(authConstants.UPDATE_USER_ERROR, error.message));
        // }
        // else {
        //   dispatch(failure(authConstants.UPDATE_USER_ERROR, error.message));
        // }
      }
    );
  };
}

function updateUserProfileImage(data) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_USERIMAGE_REQUEST));

    authService.updateUserProfileImage(data).then(
      (res) => {
        dispatch(success(authConstants.UPDATE_USERIMAGE_SUCCESS, res));
        toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        // if (error.status !== 404 && error.message) {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
        dispatch(failure(authConstants.UPDATE_USER_ERROR, error.message));
        // }
        // else {
        //   dispatch(failure(authConstants.UPDATE_USER_ERROR, error.message));
        // }
      }
    );
  };
}

function updateUserPassword(data) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_USER_PASSWORD_REQUEST));

    authService.updateUserPassword(data).then(
      (res) => {
        dispatch(
          success(authConstants.UPDATE_USER_PASSWORD_SUCCESS, res.message)
        );
        // toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(
            failure(authConstants.UPDATE_USER_PASSWORD_ERROR, error.message)
          );
        }
      }
    );
  };
}

function deactivateAccount() {
  return (dispatch) => {
    dispatch(request(authConstants.DEACTIVATE_TA_ACCOUNT));

    authService.deactivateAccount().then(
      (res) => {
        dispatch(success(authConstants.DEACTIVATE_TA_ACCOUNT_SUCCESS, res));
      },
      (error) => {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
        dispatch(
          failure(authConstants.DEACTIVATE_TA_ACCOUNT_ERROR, error.message)
        );
      }
    );
  };
}

function request(type) {
  return { type: type };
}
function success(type, data) {
  return { type: type, payload: data };
}
function failure(type, error) {
  return { type: type, payload: error };
}
