import React from "react";
import Analytics from "components/common/Analytics";
import { ReactComponent as BluepersonIcon } from "assets/images/dashboard-icons/blue-person.svg";
import { ReactComponent as RedpersonIcon } from "assets/images/dashboard-icons/red-person.svg";
import { useSelector } from "react-redux";

const UserAnalytics = () => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <div className="px-8">
        <div className="flex flex-wrap [&>*:nth-child(2)]:mr-0 px-[20px] pt-[20px] bg-white rounded w-2/3">
          <Analytics
            title="Active Users"
            loading={user?.usersLoading}
            value={user?.users?.numberOfActiveFfs}
            image={<BluepersonIcon />}
            half={true}
          />
          <Analytics
            title="Inactive Users"
            loading={user?.usersLoading}
            value={user?.users?.numberofinActiveFfs}
            image={<RedpersonIcon />}
            half={true}
          />
        </div>
      </div>
    </>
  );
};

export default UserAnalytics;
