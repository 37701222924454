import { getColor, getInitials } from "helpers";
import React from "react";
import styled from "styled-components";
import countries from "variables/countries";

const Rate = ({ rate, setSelectedRate }) => {
  const userName =
    rate?.freightForwarder?.profile?.businessName ||
    rate?.freightForwarder?.fullName ||
    rate?.source;

  return (
    <div className="w-full text-[#142837] cursor-pointer duration-300 hover:bg-[#ECFAF8] text-sm px-6 py-4 bg-white rounded-md flex mb-2 items-center">
      <div className="w-[25%] shrink-0 pr-4">
        <p>
          {rate?.portOfOrigin?.address ||
            (rate?.portOfOrigin?.country &&
              countries.find(
                (c) => c.abbreviation === rate?.portOfOrigin?.country
              )?.name)}
        </p>
        <span className="material-icons text-[#142837]">arrow_downward</span>
        <p>
          {rate?.portOfDestination?.address ||
            (rate?.portOfDestination?.country &&
              countries.find(
                (c) => c.abbreviation === rate?.portOfDestination?.country
              )?.name)}
        </p>
      </div>
      <div className="w-[15%] shrink-0">
        <Status
          className={`text-[#1B2BBC] max-w-fit rounded-md select-none capitalize font-medium ${
            rate?.active ? "active" : "inactive"
          }`}
        >
          {rate?.active ? "Active" : "Inactive"}
        </Status>
      </div>
      <div className="w-[15%] shrink-0 capitalize">
        {rate?.movementType || "--"}
      </div>
      <div className="w-[15%] shrink-0 capitalize">{rate?.loadType}</div>
      <div className="w-[24%] shrink-0 capitalize">
        <div className="flex items-center mb-4">
          {rate?.freightForwarder?.profile?.logo ? (
            <Passport
              url={rate?.freightForwarder?.profile?.logo}
              className="w-12 h-12 shrink-0 rounded-full bg-cover bg-center mr-4 border border-gray"
            ></Passport>
          ) : (
            <PassportHolder
              bg={getColor(userName)}
              className="w-12 h-12 shrink-0 rounded-full bg-cover bg-center mr-4 flex items-center justify-center"
            >
              <span className="text-white">{getInitials(userName)}</span>
            </PassportHolder>
          )}
          <div>
            <p className="font-medium text-sm">{userName}</p>
            <p className="text-xs lowercase">
              {rate?.freightForwarder?.profile?.email ||
                rate?.freightForwarder?.email}
            </p>
          </div>
        </div>
      </div>
      <div className="w-[6%] shrink-0 flex justify-center">
        <button
          type="button"
          className="bg-white drop-shadow border-b border-[#16C6A4] w-full h-[30px] rounded-md text-black font-medium relative"
          onClick={() => {}}
        >
          View
        </button>
      </div>
    </div>
  );
};

const Status = styled.div`
  &.active {
    color: #77acf1;
  }
  &.inactive {
    color: #e35328;
  }
`;

const Passport = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
`;

const PassportHolder = styled.div`
  background-color: ${(props) => props.bg};
`;

export default Rate;
