export function getCountryfromLatLng(address) {
  const googleMaps = window.google.maps;

  return new Promise((resolve, reject) => {
    const geocoder = new googleMaps.Geocoder();

    geocoder.geocode({ location: address }, (results, status) => {
      if (status === "OK") {
        resolve({
          address,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          details: results[0].address_components,
        });
      } else {
        resolve({
          address,
          lat: 0,
          lng: 0,
          details: [],
        });
      }
    });
  });
}
