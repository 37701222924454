import React, { useEffect } from "react";
import { ReactComponent as StashIcon } from "assets/images/dashboard-icons/stash.svg";
import { ReactComponent as BluegraphIcon } from "assets/images/dashboard-icons/blue-graph.svg";
import { ReactComponent as GreengraphIcon } from "assets/images/dashboard-icons/green-graph.svg";
import { ReactComponent as RedgraphIcon } from "assets/images/dashboard-icons/red-graph.svg";
import AnalyticsChart from "../AnalyticsChart";
import Analytics from "components/common/Analytics";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "actions";
import { formatCurrency, truncateNumber } from "helpers";
import { shipmentActions } from "actions/shipmentActions";
import { quoteActions } from "actions/quoteActions";

const DashboardIndex = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);
  const shipment = useSelector((state) => state.shipment);
  const quote = useSelector((state) => state.quote);

  useEffect(() => {
    dispatch(dashboardActions.getAnalytics());
    dispatch(shipmentActions.getAnalytics({ groupBy: "month" }));
    dispatch(quoteActions.getAnalytics({ groupBy: "month" }));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="p-8 pb-4 border-b border-[#D9D9D9]">
        <div className="p-2 bg-white max-w-fit rounded-lg mb-8">
          <div className="h-[35px] bg-[#16C6A4] text-white flex items-center px-8">
            Overview
          </div>
        </div>
        <div className="flex flex-wrap [&>*:nth-child(3)]:mr-0">
          <Analytics
            title="Gross Merchandise Value of Goods Moved"
            loading={dashboard?.analyticsLoading}
            value={
              dashboard?.analytics?.grossMerchandiseValueOfGoods &&
              `$${truncateNumber(
                dashboard?.analytics?.grossMerchandiseValueOfGoods
              )}`
            }
            valueRaw={
              dashboard?.analytics?.grossMerchandiseValueOfGoods &&
              `$${formatCurrency(
                dashboard?.analytics?.grossMerchandiseValueOfGoods,
                2
              )}`
            }
            image={<StashIcon />}
          />
          <Analytics
            title="Total Value of Quotes Created"
            loading={dashboard?.analyticsLoading}
            value={
              dashboard?.analytics?.totalValueOfQuotesCreated &&
              `$${truncateNumber(
                dashboard?.analytics?.totalValueOfQuotesCreated
              )}`
            }
            valueRaw={
              dashboard?.analytics?.totalValueOfQuotesCreated &&
              `$${formatCurrency(
                dashboard?.analytics?.totalValueOfQuotesCreated,
                2
              )}`
            }
            image={<StashIcon />}
          />
          <Analytics
            title="Total Value of Quotes Accepted"
            loading={dashboard?.analyticsLoading}
            value={
              dashboard?.analytics?.totalValueOfQuotesAccepted &&
              `$${truncateNumber(
                dashboard?.analytics?.totalValueOfQuotesAccepted
              )}`
            }
            valueRaw={
              dashboard?.analytics?.totalValueOfQuotesAccepted &&
              `$${formatCurrency(
                dashboard?.analytics?.totalValueOfQuotesAccepted,
                2
              )}`
            }
            image={<StashIcon />}
          />
          <Analytics
            title="Gross Transaction Value processed by FF"
            loading={dashboard?.analyticsLoading}
            value={
              dashboard?.analytics?.grossTransactionValueProcessed &&
              `$${truncateNumber(
                dashboard?.analytics?.grossTransactionValueProcessed,
                2
              )}`
            }
            valueRaw={
              dashboard?.analytics?.grossTransactionValueProcessed &&
              `$${formatCurrency(
                dashboard?.analytics?.grossTransactionValueProcessed,
                2
              )}`
            }
            image={<StashIcon />}
          />
        </div>
      </div>
      <div className="p-8 border-b border-[#D9D9D9]">
        <div className="p-2 bg-white max-w-fit rounded-lg mb-8">
          <div className="h-[35px] bg-[#538BD3] text-white flex items-center px-8">
            Shipment Statistics
          </div>
        </div>
        <div className="flex flex-wrap [&>*:nth-child(3)]:mr-0">
          <Analytics
            title="Total No. of Shipments Created"
            loading={shipment?.analyticsLoading}
            value={shipment?.analytics?.created}
            image={<BluegraphIcon />}
          />
          <Analytics
            title="Total No. of Shipments Delivered"
            loading={shipment?.analyticsLoading}
            value={shipment?.analytics?.delivered}
            image={<GreengraphIcon />}
          />
          <Analytics
            title="Total No. of Cancelled Shipments"
            loading={shipment?.analyticsLoading}
            value={shipment?.analytics?.cancelled}
            image={<RedgraphIcon />}
          />
        </div>
        <AnalyticsChart />
      </div>
      <div className="p-8 border-b border-[#D9D9D9]">
        <div className="p-2 bg-white max-w-fit rounded-lg mb-8">
          <div className="h-[35px] bg-[#E35328] text-white flex items-center px-8">
            Quote Statistics
          </div>
        </div>
        <div className="flex flex-wrap [&>*:nth-child(3)]:mr-0">
          <Analytics
            title="Total No. of Quotes Created"
            loading={quote?.analyticsLoading}
            value={quote?.analytics?.quotesByFF}
            image={<BluegraphIcon />}
          />
          <div className="w-[calc((100%-40px)/3)] py-4 px-6 bg-white mr-[20px] mb-[20px] shrink-0 rounded-md drop-shadow">
            <div className="h-[50px] text-sm font-medium text-[#6B778C]">
              Total No. of Quotes Delivered
            </div>
            <div className="flex justify-between items-center">
              <p className="font-bold text-3xl text-[#3F3D56]">100</p>
              <GreengraphIcon />
            </div>
          </div>
          <div className="w-[calc((100%-40px)/3)] py-4 px-6 bg-white mr-[20px] mb-[20px] shrink-0 rounded-md drop-shadow">
            <div className="h-[50px] text-sm font-medium text-[#6B778C]">
              Total No. of Quotes Cancelled
            </div>
            <div className="flex justify-between items-center">
              <p className="font-bold text-3xl text-[#3F3D56]">20</p>
              <RedgraphIcon />
            </div>
          </div>
        </div>
        <AnalyticsChart />
      </div>
    </>
  );
};

export default DashboardIndex;
