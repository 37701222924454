import React from "react";
import Analytics from "components/common/Analytics";
import { ReactComponent as BluewalletIcon } from "assets/images/dashboard-icons/payments-blue.svg";
import { ReactComponent as GreenwalletIcon } from "assets/images/dashboard-icons/payments-green.svg";
import { ReactComponent as RedwalletIcon } from "assets/images/dashboard-icons/payments-red.svg";
import { useSelector } from "react-redux";
import { currencySign, formatCurrency } from "helpers";

const PaymentAnalytics = () => {
  const payment = useSelector((state) => state.payment);

  return (
    <>
      <div className="px-8 pb-4 mb-4">
        <div className="flex flex-wrap [&>*:nth-child(3)]:mr-0 px-[20px] pt-[20px] bg-white rounded">
          <Analytics
            currency={"USD"}
            loading={payment?.analyticsLoading}
            value={`${currencySign("USD")}${formatCurrency(
              payment?.analytics.currencyData?.["USD"]?.value
                ?.grossTransactionValue
            ) || 0}`}
            increase={`${
              payment?.analytics.currencyData?.["USD"]?.trend
                ?.grossTransactionValue?.percentageChange || 0
            }%`}
            border="border-[#16C6A4]"
          />
          <Analytics
            currency={"NGN"}
            loading={payment?.analyticsLoading}
            value={`${currencySign("NGN")}${formatCurrency(
              payment?.analytics.currencyData?.["NGN"]?.value
                ?.grossTransactionValue
            ) || 0}`}
            increase={`${
              payment?.analytics.currencyData?.["NGN"]?.trend
                ?.grossTransactionValue?.percentageChange || 0
            }%`}
            border="border-[#77ACF1]"
          />
          <Analytics
            currency={"EUR"}
            loading={payment?.analyticsLoading}
            value={`${currencySign("EUR")}${formatCurrency(
              payment?.analytics.currencyData?.["EUR"]?.value
                ?.grossTransactionValue
            ) || 0}`}
            increase={`${
              payment?.analytics.currencyData?.["EUR"]?.trend
                ?.grossTransactionValue?.percentageChange || 0
            }%`}
            border="border-[#E35328]"
          />
        </div>
      </div>
      <div className="px-8">
        <div className="flex flex-wrap [&>*:nth-child(3)]:mr-0 px-[20px] pt-[20px] bg-white rounded">
          <Analytics
            title="No. of Pending Payment"
            loading={payment?.analyticsLoading}
            value={payment?.analytics?.pending?.value}
            image={<BluewalletIcon />}
            increase={`${payment?.analytics?.pending?.trend?.percentageChange}%`}
          />
          <Analytics
            title="No. of Payment links Generated"
            loading={payment?.analyticsLoading}
            value={payment?.analytics?.paymentLinksGenerated?.value}
            image={<GreenwalletIcon />}
            increase={`${payment?.analytics?.paymentLinksGenerated?.trend?.percentageChange}%`}
          />
          <Analytics
            title="No. of Payment links Converted"
            loading={payment?.analyticsLoading}
            value={payment?.analytics?.paymentLinksConverted?.value}
            image={<RedwalletIcon />}
            increase={`${payment?.analytics?.paymentLinksConverted?.trend?.percentageChange}%`}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentAnalytics;
