/**
 * Update object in an array
 * @param {{}[]} list The list of data items to update
 * @param {any} item The item to be used as an update
 * @param {string} key The property of the `lists`'s item to use in matching
 * @returns []
 */
export const updateArrayItem = (list, item, key) => {
    if (!list) return [];

    return list.map((listItem) => {
        if (typeof listItem !== "object") return listItem;

        if (listItem[key] === item[key]) {
            return item;
        }

        return listItem;
    });
};

/**
 * Remove object in an array
 * @param {{}[]} list The list of data items to remove from
 * @param {any} item The item whose property is to be used to remove
 * @param {string} key The property of the `lists`'s item to use in matching
 * @returns []
 */
export const removeArrayItem = (list, item, key) => {
    if (!list) return [];

    return list.filter((listItem) => {
        if (typeof listItem !== "object") return true;

        return listItem[key] !== item[key];
    });
};

/**
 * 
 * @param {[]} arr1
 * @param {[]} arr2
 * @param {(item: T, item: T) => boolean} fn Callback function to use when items are objects or arrays
 * @returns 
 */
export const intersectArrays = (arr1, arr2, fn) => {
    if(fn) {
        return arr1.filter((arr1Item) => arr2.some((arr2Item) => fn(arr1Item, arr2Item)));
    }
    return arr1.filter((value) => arr2.includes(value));
} 