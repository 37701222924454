import React from "react";
import { ReactComponent as SignupImage } from "assets/images/signup-verification.svg";
import { useDispatch } from "react-redux";
import { authActions } from "actions";

const SignupVerification = ({ auth, setModal, setStep }) => {
  const dispatch = useDispatch();

  const resendCode = () => {
    if (auth.resendingVerificationCode === false) {
      dispatch(authActions.resendVerificationCode());
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-1/3 p-12 bg-white text-center text-sm font-medium"
    >
      <SignupImage className="m-auto mb-6" />
      <p className="mb-4">
        A verification code has been sent to your registered Email to confirm
        your account
      </p>
      <p className="mb-8">
        <span
          className={`select-none ${
            auth.resendingVerificationCode
              ? "text-gray"
              : "text-[#E35328] underline cursor-pointer"
          }`}
          onClick={resendCode}
        >
          Resend
        </span>{" "}
        if you did not receive any
      </p>
      <button
        type="button"
        className="bg-[#E35328] w-full h-[50px] rounded-md text-white relative"
        onClick={() => {
          setModal(false);
          setStep(1);
        }}
      >
        Proceed
      </button>
    </div>
  );
};

export default SignupVerification;
