export function getColor(fullname, randomness = 10) {
    if (fullname) {
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const colorpalette = [
            "#96ceb4",
            "#ff6f69",
            "#ffcc5c",
            "#88d8b0",
            "#0F5959",
            "#571845",
            "#900C3E",
            "#C70039",
            "#FF5733",
            "#FFC300",
            "#B77F66",
            "#9C528B",
            "#8F3985",
            "#91F9E5",
            "#E3B505",
            "#EE8434",
        ];

        if(randomness === 0) {
            randomness = colorpalette.length;
        }

        return colorpalette[
            alphabet.indexOf(fullname.charAt(0).toUpperCase()) % randomness
        ];
    }
    return "#000";
}
/**
 * Source: https://gist.github.com/robertnsharp/49fd46a071a267d9e5dd
 * @param {string} inputStr 
 * @returns string
 */
export function generatePastelColor(inputStr) { 
    //magic to convert strings to a nice pastel colour based on first two chars
    //
    // every string with the same first two chars will generate the same pastel colour
    //TODO: adjust base colour values below based on theme
    var baseRed = 17;
    var baseGreen = 82;
    var baseBlue = 251;

    //lazy seeded random hack to get values from 0 - 256
    //for seed just take bitwise XOR of first two chars
    var seed = inputStr.charCodeAt(0) ^ inputStr.charCodeAt(1);
    var rand_1 = Math.abs((Math.sin(seed++) * 10000)) % 256;
    var rand_2 = Math.abs((Math.sin(seed++) * 10000)) % 256;
    var rand_3 = Math.abs((Math.sin(seed++) * 10000)) % 256;

    //build colour
    var red = Math.round((rand_1 + baseRed) / 2);
    var green = Math.round((rand_2 + baseGreen) / 2);
    var blue = Math.round((rand_3 + baseBlue) / 2);

    return `rgba(${red}, ${green}, ${blue})`;
  }