import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as ChartIcon } from "assets/images/dashboard-icons/chart-3.svg";
import { ReactComponent as Select } from "assets/images/dashboard-icons/select-dropdown.svg";
import { ReactComponent as Check } from "assets/images/dashboard-icons/check.svg";
import { ReactComponent as Upload } from "assets/images/dashboard-icons/upload.svg";
import { ReactComponent as Calculator } from "assets/images/dashboard-icons/calculator.svg";
import { ReactComponent as Excel } from "assets/images/dashboard-icons/excel.svg";
import { ReactComponent as Add } from "assets/images/dashboard-icons/add-black.svg";
import Rate from "./Rate";
import { useSelector } from "react-redux";
import Loading from "components/common/Loading";
import { usePrevious } from "helpers";
import { Link } from "react-router-dom";

const RateList = ({ fetchData }) => {
  const rate = useSelector((state) => state.rate);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedRate, setSelectedRate] = useState(null);
  const prevIsVisible = usePrevious(isVisible);

  const observerTarget = useRef(null);

  const callbackFunction = useCallback((entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    if (prevIsVisible === false && isVisible === true) {
      fetchData(rate?.rates?.pagination?.next);
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      threshold: 1,
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line
        observer.unobserve(observerTarget.current);
      }
    };
    // eslint-disable-next-line
  }, [observerTarget]);

  return (
    <>
      <div className="p-8">
        <div className="flex justify-between mb-8">
          <div className="flex relative">
            <div className="h-[40px] w-[40px] rounded-l mr-2 bg-[#16C6A4] flex items-center justify-center">
              <ChartIcon className="h-[10px] w-auto" />
            </div>
            <div className="h-[40px] px-6 pr-3 flex bg-white mr-2">
              <div className="flex items-center mr-4">
                <div className="bg-[#1B2BBC]/20 rounded px-2 py-0.5 text-sm">
                  {rate?.rates?.rates?.length}
                </div>
              </div>
              <div className="group h-[50px]">
                <div className="h-[40px] w-[150px] rounded flex justify-between select-none cursor-pointer text-[#142837]">
                  <div className="pl-4 flex items-center text-sm">Active</div>
                  <div className="h-[40px] w-[40px] flex items-center justify-center">
                    <Select />
                  </div>
                </div>
                <div className="border border-gray bg-white group-hover:block hidden mt-2 px-4 py-2 text-sm rounded select-none">
                  <p className="text-sm pb-2 mb-3 border-b border-gray">
                    <span className="text-[#E35328]">Filter by:</span>{" "}
                    <span className="text-[#6B778C]">Status</span>
                  </p>
                  <div className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                    Active
                  </div>
                  <div className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                    Delivered
                  </div>
                  <div className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                    Pending
                  </div>
                  <div className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                    Cancelled
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[40px] w-[40px] rounded-r bg-[#16C6A4] flex items-center justify-center">
              <Check className="h-[15px] w-auto cursor-pointer" />
            </div>
          </div>
          <div className="group h-[50px] relative z-10 text-sm">
            <button className="h-[40px] px-4 bg-white flex items-center rounded-md shadow cursor-pointer">
              <Add className="mr-2" />
              <span className="text-sm">Add Rates</span>
            </button>
            <div className="group-hover:block hidden w-[380px] py-8 bg-white rounded-md mt-[5px] absolute right-0 shadow-md">
              <div className="hover:bg-[#16C6A4]/20 px-8 py-4 cursor-pointer">
                <Upload className="mb-2" />
                <p>Upload a CSV file</p>
                <p className="underline text-[#6B778C]">
                  Click to upload from your device
                </p>
              </div>
              <div className="hover:bg-[#16C6A4]/20 px-8 py-4 cursor-pointer flex">
                <p className="underline">
                  Click to download Tradeally Excel template If you don’t have
                  an existing Template
                </p>
                <Excel className="ml-10 shrink-0 w-[45px] h-auto" />
              </div>
              <div className="px-8">
                <div className="border-b border-gray my-4"></div>
              </div>
              <Link to="/dashboard/rates/create">
                <div className="hover:bg-[#16C6A4]/20 px-8 py-4 cursor-pointer">
                  <Calculator className="mb-2" />
                  <p>Enter your rate manually - Single</p>
                  <p className="underline text-[#6B778C]">
                    Click to Manually input your rate details
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full overflow-x-auto pb-20">
          <div className="w-full h-[50px] text-white text-sm px-6 bg-[#16C6A4] rounded-md flex items-center mb-2">
            <div className="w-[25%] shrink-0">Route</div>
            <div className="w-[15%] shrink-0">Status</div>
            <div className="w-[15%] shrink-0">Movement Type</div>
            <div className="w-[15%] shrink-0">Load Type</div>
            <div className="w-[20%] shrink-0">Uploaded by</div>
            <div className="w-[5%] shrink-0"></div>
          </div>
          {rate?.rates?.rates ? (
            <>
              {rate?.rates?.rates?.map((r, index) => (
                <React.Fragment key={index}>
                  <Rate
                    rate={r}
                    selectedRate={selectedRate}
                    setSelectedRate={setSelectedRate}
                  />
                </React.Fragment>
              ))}
              <div className="relative w-full h-[50px] flex items-center justify-center mt-8">
                {rate?.ratesLoading && (
                  <Loading size={30} color="black m-auto" />
                )}
              </div>
            </>
          ) : (
            <p className="w-full text-center pt-10">Loading...</p>
          )}
          <div ref={observerTarget}></div>
        </div>
      </div>
    </>
  );
};

export default RateList;
