import React from "react";
import AnalyticsChart from "../AnalyticsChart";
import Analytics from "components/common/Analytics";
import { ReactComponent as BluegraphIcon } from "assets/images/dashboard-icons/blue-graph.svg";
import { ReactComponent as GreengraphIcon } from "assets/images/dashboard-icons/green-graph.svg";
import { ReactComponent as RedgraphIcon } from "assets/images/dashboard-icons/red-graph.svg";
import { useSelector } from "react-redux";

const ShipmentAnalytics = () => {
  const shipment = useSelector((state) => state.shipment);

  return (
    <>
      <div className="p-8 pb-4 border-b border-[#D9D9D9]">
        <p className="text-2xl font-medium mb-4">Statistics</p>
        <div className="flex flex-wrap [&>*:nth-child(3)]:mr-0">
          <Analytics
            title={"Total No. of Shipments"}
            loading={shipment?.analyticsLoading}
            value={shipment?.analytics?.created}
          />
        </div>
      </div>
      <div className="p-8">
        <div className="flex flex-wrap [&>*:nth-child(3)]:mr-0">
          <Analytics
            title="Total No. of Pending Shipments"
            loading={shipment?.analyticsLoading}
            value={shipment?.analytics?.pending}
            image={<BluegraphIcon />}
          />
          <Analytics
            title="Total No. of Shipments Delivered"
            loading={shipment?.analyticsLoading}
            value={shipment?.analytics?.delivered}
            image={<GreengraphIcon />}
          />
          <Analytics
            title="Total No. of Cancelled Shipment"
            loading={shipment?.analyticsLoading}
            value={shipment?.analytics?.cancelled}
            image={<RedgraphIcon />}
          />
          <Analytics
            title="Total No. of Active Shipment"
            loading={shipment?.analyticsLoading}
            value={shipment?.analytics?.active}
            image={<BluegraphIcon />}
          />
        </div>
        <AnalyticsChart title="The total no of Shipments created" />
      </div>
    </>
  );
};

export default ShipmentAnalytics;
