import { rateActions } from "actions";
import Loading from "components/common/Loading";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const PublishRate = ({ rate, setModal }) => {
  const rateSelector = useSelector((state) => state.rate);
  const dispatch = useDispatch();

  const sendRate = () => {
    dispatch(rateActions.createRate(rate));
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-1/3 p-12 bg-white text-center text-sm font-medium"
    >
      <p className="mb-10 text-xl">
        Do you want to proceed to Publish your rate?
      </p>
      <div className="flex justify-center">
        <button
          type="button"
          className="bg-[#16C6A4] w-full h-[45px] rounded-md text-lg text-white relative mr-6"
          onClick={sendRate}
          disabled={rateSelector.creatingRate}
        >
          {rateSelector.creatingRate ? <Loading /> : "Yes"}
        </button>
        <button
          type="button"
          className="bg-white w-full h-[45px] border border-gray rounded-md text-lg text-[#D02F44] relative"
          disabled={rateSelector.creatingRate}
          onClick={() => {
            setModal(false);
          }}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default PublishRate;
