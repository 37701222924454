import React, { useState } from "react";
import UserAnalytics from "./Analytics";
import UserList from "./List";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as ChartIcon } from "assets/images/dashboard-icons/chart-3.svg";
import { ReactComponent as Check } from "assets/images/dashboard-icons/check.svg";
import Loading from "components/common/Loading";

const UserStats = ({ user, fetchData }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  return (
    <>
      <div className="px-8 flex mb-6 relative">
        <div className="h-[40px] w-[40px] rounded-l mr-2 bg-[#16C6A4] flex items-center justify-center">
          <ChartIcon className="h-[10px] w-auto" />
        </div>
        <div className="h-[40px] px-4 pr-6 mr-2 flex bg-white">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            withPortal
            placeholderText="Choose date range"
          />
        </div>
        <div className="h-[40px] w-[40px] rounded-r bg-[#16C6A4] flex items-center justify-center">
          <Check className="h-[15px] w-auto cursor-pointer" />
        </div>
      </div>
      <div className="px-8 mb-6 relative">
        <p className="text-[#16C6A4] text-sm mb-2">
          Total No. of Users on Tradeally
        </p>

        {user?.usersLoading ? (
          <div className="relative w-[30px] h-[30px] flex items-center justify-center">
            <Loading size={25} color="black" />
          </div>
        ) : (
          <p className="font-bold text-2xl text-[#142837]">
            {user?.users?.totalFf}
          </p>
        )}
      </div>
      <UserAnalytics />
      <div className="p-8 pb-0 mt-4 flex justify-between">
        <div className="p-2 bg-white max-w-fit rounded-lg">
          <div className="h-[35px] bg-[#16C6A4] text-white flex items-center px-8">
            User List
          </div>
        </div>
      </div>
      <UserList fetchData={fetchData} />
    </>
  );
};

export default UserStats;
