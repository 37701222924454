import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { ReactComponent as AuthLogo } from "assets/images/logo.svg";
import { ReactComponent as Air } from "assets/images/dashboard-icons/modes/air.svg";
import { ReactComponent as Land } from "assets/images/dashboard-icons/modes/land.svg";
import { ReactComponent as Sea } from "assets/images/dashboard-icons/modes/sea.svg";
import styled from "styled-components";
import { formatCurrency } from "helpers";

const UserDetails = ({ selectedPayment: payment, setModal }) => {
  const [pkg, setPkg] = useState(null);

  useEffect(() => {
    setPkg(null);
  }, []);

  return (
    <div className="w-full h-screen flex flex-col no-shrink overflow-y-auto py-12">
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-1/2 m-auto bg-white"
      >
        <div className="px-6 py-4 flex justify-between">
          <div className="w-[40px] h-[40px] bg-[#16C6A4] rounded-md flex justify-center items-center">
            <AuthLogo className="h-[30px] w-auto" />
          </div>
          <div
            onClick={() => setModal(false)}
            className="w-[40px] h-[40px] rounded-md flex justify-center items-center select-none cursor-pointer"
          >
            <span className="material-icons">close</span>
          </div>
        </div>
        <div className="border-b border-gray pb-4 mb-4">
          <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm font-bold">
            <div className="w-1/2 shrink-none">
              PAYMENT ID - TA{payment?.mvxid}
            </div>
            <div className="w-1/2 shrink-none text-[#16C6A4]">GMV-$2500</div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Route</div>
            <div className="w-1/2 shrink-none">
              {payment?.origin?.address} - {payment?.destination?.address}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Date Created</div>
            <div className="w-1/2 shrink-none">
              <Moment format="ddd DD-MMM-YY">{payment?.createdAt}</Moment>
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Mode</div>
            <div className="w-1/2 shrink-none">
              {payment?.serviceMode === "air" && (
                <Air className="h-[15px] w-auto" />
              )}
              {payment?.serviceMode === "land" && (
                <Land className="h-[15px] w-auto" />
              )}
              {payment?.serviceMode === "ocean" && (
                <Sea className="h-[17px] w-auto" />
              )}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Status</div>
            <div className="w-1/2 shrink-none">
              <Status
                className={`select-none capitalize ${payment?.currentStatus}`}
              >
                {payment?.currentStatus}
              </Status>
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Freight Forwarder</div>
            <div className="w-1/2 shrink-none">
              {payment?.freightForwarder?.profile?.businessName}
              {payment?.freightForwarder?.fullName && (
                <>
                  <br />
                  {payment?.freightForwarder?.fullName}
                </>
              )}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">ETA</div>
            <div className="w-1/2 shrink-none">1 Sept, 2023</div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">ETD</div>
            <div className="w-1/2 shrink-none">7 Sept, 2023</div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">PoL</div>
            <div className="w-1/2 shrink-none">Lift Off</div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Carrier</div>
            <div className="w-1/2 shrink-none">Pillow logistics</div>
          </div>
        </div>
        <div className="border-b border-gray pb-4 mb-4">
          <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm font-bold">
            <div className="w-1/2 shrink-none">PACKAGE DETAILS</div>
          </div>
          {payment?.packages?.length > 0 ? (
            payment?.packages?.map((s, index) => (
              <>
                <div
                  key={index}
                  onClick={() =>
                    setPkg((pkg) => (pkg === index ? null : index))
                  }
                  className="px-6 py-2 flex text-sm text-[#142837] cursor-pointer"
                >
                  <div className="w-1/2 shrink-none">Package {index + 1}</div>
                  <div className="w-1/2 shrink-none">
                    <span className="material-icons text-sm text-[#16C6A4]">
                      arrow_forward_ios
                    </span>
                  </div>
                </div>
                {pkg !== null && pkg === index ? (
                  <div className="px-6">
                    <div className="text-sm rounded-md ">
                      <div className="bg-[#16C6A4] text-white py-2 text-center font-medium overflow-hidden rounded-t-md">
                        Package {index + 1}
                      </div>
                      <div className="text-sm text-[#142837] rounded-b-md overflow-hidden border border-gray">
                        <div className="px-6 py-2 flex">
                          <div className="w-1/2 shrink-none">Cargo Type</div>
                          <div className="w-1/2 shrink-none capitalize">
                            {payment?.packageType}
                          </div>
                        </div>
                        <div className="px-6 py-2 flex">
                          <div className="w-1/2 shrink-none">Package Type</div>
                          <div className="w-1/2 shrink-none">
                            {s?.packageType}
                          </div>
                        </div>
                        <div className="px-6 py-2 flex">
                          <div className="w-1/2 shrink-none">No. of Unit</div>
                          <div className="w-1/2 shrink-none">{s?.units}</div>
                        </div>
                        <div className="px-6 py-2 flex">
                          <div className="w-1/2 shrink-none">Pallet Time</div>
                          <div className="w-1/2 shrink-none">---</div>
                        </div>
                        <div className="px-6 py-2 flex">
                          <div className="w-1/2 shrink-none">
                            Dimension per Unit
                          </div>
                          <div className="w-1/2 shrink-none">
                            L : {s?.length?.value}
                            {s?.length?.unit} | W : {s?.width?.value}
                            {s?.width?.unit} | H : {s?.height?.value}
                            {s?.height?.unit}
                          </div>
                        </div>
                        <div className="px-6 py-2 flex">
                          <div className="w-1/2 shrink-none">Weight</div>
                          <div className="w-1/2 shrink-none">
                            {s?.weight?.value}{" "}
                            <span className="uppercase">{s?.weight?.unit}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ))
          ) : (
            <p className="text-sm text-gray-500 py-6 text-center">
              No packages found
            </p>
          )}
        </div>
        <div className="border-b border-gray pb-4 mb-4">
          <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm font-bold">
            <div className="w-1/2 shrink-none">GOODS DETAILS</div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Value of Goods</div>
            <div className="w-1/2 shrink-none">
              {payment?.goodsDetails?.value?.amount
                ? `${payment?.goodsDetails?.value?.currency} ${formatCurrency(
                    payment?.goodsDetails?.value?.amount,
                    2
                  )}`
                : "---"}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Status of Goods</div>
            <div className="w-1/2 shrink-none">
              {payment?.goodsDetails?.goodsStatus}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Description</div>
            <div className="w-1/2 shrink-none">
              {payment?.goodsDetails?.description}
            </div>
          </div>
        </div>
        <div className="border-b border-gray pb-4 mb-4">
          <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm font-bold">
            <div className="w-1/2 shrink-none">SHIPPER DETAILS</div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Company Name</div>
            <div className="w-1/2 shrink-none">
              {payment?.shipperDetails?.fullName}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Address</div>
            <div className="w-1/2 shrink-none">
              {payment?.shipperDetails?.address?.address}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Email</div>
            <div className="w-1/2 shrink-none">
              {payment?.shipperDetails?.email}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Phone No.</div>
            <div className="w-1/2 shrink-none">
              {payment?.shipperDetails?.phoneNumberExtention
                ? payment?.shipperDetails?.phoneNumberExtention
                : ""}{" "}
              {payment?.shipperDetails?.phoneNumber
                ? payment?.shipperDetails?.phoneNumber
                : ""}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">FAX</div>
            <div className="w-1/2 shrink-none">
              {payment?.shipperDetails?.fax
                ? payment?.shipperDetails?.fax
                : "---"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Status = styled.p`
  &.active {
    color: #1b2bbc;
  }
  &.pending {
    color: #b417cd;
  }
  &.cancelled {
    color: #e35328;
  }
  &.delivered {
    color: #16c6a4;
  }
`;

export default UserDetails;
