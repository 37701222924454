import React from "react";

const Checkbox = ({ checked }) => {
  return (
    <label
      onClick={(e) => e.preventDefault()}
      class="relative inline-flex items-center cursor-pointer"
    >
      <input type="checkbox" value="" class="sr-only peer" checked={checked} />
      <div class="w-[3.2rem] h-8 bg-gray-700 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] peer-checked:after:-left-[7px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all peer-checked:bg-[#16C6A4]"></div>
    </label>
  );
};

export default Checkbox;
