import { dashboardService } from "services";
import { dashboardConstants } from "constants";

function getAnalytics() {
  return (dispatch) => {
    function request() {
      return { type: dashboardConstants.FETCH_CARDANALYTICS_REQUEST };
    }
    function success(analytics) {
      return { type: dashboardConstants.FETCH_CARDANALYTICS_SUCCESS, analytics };
    }
    function failure(error) {
      return { type: dashboardConstants.FETCH_CARDANALYTICS_ERROR, error };
    }

    dispatch(request());

    dashboardService.getAnalytics().then(
      (analytics) => {
        dispatch(success(analytics));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

export const dashboardActions = {
  getAnalytics,
};
