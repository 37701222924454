import { currentENVData } from "../helpers";
import { authService } from "services";

export const dashboardService = {
  getAnalytics,
};

function getAnalytics() {
  const requestOptions = {
    method: "GET",
  };

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/analytics/default`;

  return authService
    .apiGate(url, requestOptions)
    .then((analytics) => analytics?.data);
}
