import React from "react";

const Step2 = ({ rate, onChange }) => {
  const container = ["containers", "20", "40"];

  return (
    <>
      <p className="text-lg text-[#16C6A4] font-medium mb-2">Load Type</p>
      <p className="text-sm mb-4">Select the type of load for your rate</p>
      <div className="pb-6">
        <div
          className={`w-full rounded-md cursor-pointer border p-6 pb-4 mb-4 ${
            container.includes(rate.loadType) ? "border-black" : "border-gray"
          }`}
          onClick={(e) => onChange(e, "loadType", "containers")}
        >
          <div className="flex items-center justify-between">
            <div className="w-2/5 pb-3">
              <p className="text-lg font-medium text-[#142837]">Container</p>
              <p className="text-sm text-[#6B778C]">
                Select the container size for your rate
              </p>
            </div>
            <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mb-3">
              {container.includes(rate.loadType) && (
                <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
              )}
            </div>
          </div>

          {container.includes(rate.loadType) && (
            <div className="flex justify-between pt-4">
              <div
                onClick={(e) => onChange(e, "loadType", "20")}
                className="w-[45%] shrink-0 flex rounded-md cursor-pointer items-center justify-between border border-gray px-6 py-2 pb-4 mb-4"
              >
                <div className="w-2/5 pt-1">
                  <p className="text-lg font-medium text-[#142837]">20ft</p>
                </div>
                <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mt-1">
                  {rate.loadType === "20" && (
                    <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
                  )}
                </div>
              </div>
              <div
                onClick={(e) => onChange(e, "loadType", "40")}
                className="w-[45%] shrink-0 flex rounded-md cursor-pointer items-center justify-between border border-gray px-6 py-2 pb-4 mb-4"
              >
                <div className="w-2/5 pt-1">
                  <p className="text-lg font-medium text-[#142837]">40ft</p>
                </div>
                <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mt-1">
                  {rate.loadType === "40" && (
                    <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={`w-full rounded-md cursor-pointer border p-6 pb-4 mb-4 ${
            rate.loadType === "noncontainers" ? "border-black" : "border-gray"
          }`}
          onClick={(e) => onChange(e, "loadType", "noncontainers")}
        >
          <div className="flex items-center justify-between">
            <div className="w-2/5 pb-3">
              <p className="text-lg font-medium text-[#142837]">Loose Cargo</p>
              <p className="text-sm text-[#6B778C]">
                Looses cargo transportation rates are per kilogram (KG) of
                weight
              </p>
            </div>
            <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mb-3">
              {rate.loadType === "noncontainers" && (
                <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
              )}
            </div>
          </div>

          {rate.loadType === "noncontainers" && (
            <div className="flex justify-between pt-4">
              <div
                onClick={(e) =>
                  onChange(e, "package", "packageType", "Crates/Boxes")
                }
                className={`w-[45%] shrink-0 flex rounded-md cursor-pointer items-center justify-between border px-6 py-2 pb-4 mb-4 ${
                  rate.package?.packageType === "Crates/Boxes"
                    ? "border-black"
                    : "border-gray"
                }`}
              >
                <div className="w-2/5 pt-1">
                  <p className="text-lg font-medium text-[#142837]">Boxes</p>
                </div>
                <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mt-1">
                  {rate.package?.packageType === "Crates/Boxes" && (
                    <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
                  )}
                </div>
              </div>
              <div
                onClick={(e) =>
                  onChange(e, "package", "packageType", "Pallets")
                }
                className={`w-[45%] shrink-0 flex rounded-md cursor-pointer items-center justify-between border px-6 py-2 pb-4 mb-4 ${
                  rate.package?.packageType === "Pallets"
                    ? "border-black"
                    : "border-gray"
                }`}
              >
                <div className="w-2/5 pt-1">
                  <p className="text-lg font-medium text-[#142837]">Pallets</p>
                </div>
                <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mt-1">
                  {rate.package?.packageType === "Pallets" && (
                    <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {rate.loadType === "noncontainers" && (
        <>
          <p className="text-md font-medium mb-2">Units of Measurements</p>
          <div className="flex justify-between pb-10">
            <div
              onClick={(e) => onChange(e, "package", "measurement", "kg")}
              className={`w-[45%] shrink-0 flex rounded-md cursor-pointer items-center justify-between border px-6 py-2 pb-4 mb-4 ${
                rate.package?.measurement === "kg"
                  ? "border-black"
                  : "border-gray"
              }`}
            >
              <div className="w-2/5 pt-1">
                <p className="text-lg font-medium text-[#142837]">
                  Kilogram (KG)
                </p>
              </div>
              <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mt-1">
                {rate.package?.measurement === "kg" && (
                  <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
                )}
              </div>
            </div>
            <div
              onClick={(e) => onChange(e, "package", "measurement", "lbs")}
              className={`w-[45%] shrink-0 flex rounded-md cursor-pointer items-center justify-between border px-6 py-2 pb-4 mb-4 ${
                rate.package?.measurement === "lbs"
                  ? "border-black"
                  : "border-gray"
              }`}
            >
              <div className="w-2/5 pt-1">
                <p className="text-lg font-medium text-[#142837]">
                  Pounds (lbs)
                </p>
              </div>
              <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mt-1">
                {rate.package?.measurement === "lbs" && (
                  <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Step2;
