import React from "react";
import styled from "styled-components";
import { ReactComponent as AuthLogo } from "assets/images/logo.svg";
import { Link, matchPath, useLocation } from "react-router-dom";

const DashboardSidebar = ({ routes }) => {
  const location = useLocation();
  const isActive = (route) =>
    matchPath(`dashboard/${route?.path}`, location?.pathname);

  return (
    <AppLink className="w-[300px] h-full bg-white drop-shadow-md shrink-0 flex flex-col overflow-y-auto">
      <div className="h-[60px] shrink-0 w-full bg-[#16C6A4] flex justify-center items-center mb-2">
        <AuthLogo className="h-[40px] w-auto" />
      </div>
      <div className="p-8 flex items-center">
        <Passport
          url={require("assets/images/dashboard-icons/passport.jpg")}
          className="w-16 h-16 rounded-full border-4 border-[#E35328] bg-cover bg-center"
        ></Passport>
        <div className="ml-4">
          <p className="font-light text-sm">Good morning,</p>
          <p className="font-medium text-[1.3rem]">Oluwatobi</p>
        </div>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div>
          {routes
            .filter((r) => !r.position || r.position !== "bottom")
            .map((r, index) => (
              <React.Fragment key={index}>
                {r.disabled ? (
                  <div className={`app-link ${isActive(r) && "active"}`}>
                    <div className="opacity-30 px-8 py-3 font-medium flex items-center select-none cursor-default">
                      <div className="w-[30px] flex justify-center mr-4">
                        {r.icon}
                      </div>
                      <div className="green w-[30px] flex justify-center mr-4">
                        {r.iconGreen}
                      </div>
                      {r.name}
                    </div>
                  </div>
                ) : (
                  <Link
                    to={`/dashboard/${r.path}`}
                    className={`app-link ${isActive(r) && "active"}`}
                    key={index}
                  >
                    <div className="px-8 py-3 font-medium flex items-center select-none cursor-pointer">
                      <div className="w-[30px] flex justify-center mr-4">
                        {r.icon}
                      </div>
                      <div className="green w-[30px] flex justify-center mr-4">
                        {r.iconGreen}
                      </div>
                      {r.name}
                    </div>
                  </Link>
                )}
              </React.Fragment>
            ))}
        </div>
        <div className="mb-4">
          {routes
            .filter((r) => r.position === "bottom")
            .map((r, index) => (
              <div
                key={index}
                className="px-8 py-3 font-medium flex items-center select-none cursor-pointer"
              >
                <div className="w-[30px] flex justify-center mr-4">
                  {r.icon}
                </div>
                {r.name}
              </div>
            ))}
        </div>
      </div>
    </AppLink>
  );
};

const Passport = styled.div`
  background-image: url(${(props) => props.url});
`;

const AppLink = styled.div`
  & .active > div {
    background: linear-gradient(
      88.42deg,
      rgba(22, 198, 164, 0.17) 22.08%,
      rgba(168, 255, 238, 0.11) 90.35%,
      rgba(255, 255, 255, 0.11) 100%
    );
  }
  .app-link.active > div > div:not(.green) {
    display: none;
  }
  .app-link.active > div > div.green {
    display: block;
  }
  .app-link:not(.active) > div > div:not(.green) {
    display: block;
  }
  .app-link:not(.active) > div > div.green {
    display: none;
  }
  .app-link.active > div {
    color: #16c6a4;
  }
`;

export default DashboardSidebar;
