import React from "react";
import Moment from "react-moment";
import { ReactComponent as AuthLogo } from "assets/images/logo.svg";
import { ReactComponent as Air } from "assets/images/dashboard-icons/modes/air.svg";
import { ReactComponent as Land } from "assets/images/dashboard-icons/modes/land.svg";
import { ReactComponent as Sea } from "assets/images/dashboard-icons/modes/sea.svg";
import styled from "styled-components";
import { downloadURLFile, formatCurrency } from "helpers";

const QuoteDetails = ({ selectedQuote: quote, setModal }) => {
  return (
    <div className="w-full h-screen flex flex-col no-shrink overflow-y-auto py-12">
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-1/2 m-auto bg-white"
      >
        <div className="px-6 py-4 flex justify-between">
          <div className="w-[40px] h-[40px] bg-[#16C6A4] rounded-md flex justify-center items-center">
            <AuthLogo className="h-[30px] w-auto" />
          </div>
          <div
            onClick={() => setModal(false)}
            className="w-[40px] h-[40px] rounded-md flex justify-center items-center select-none cursor-pointer"
          >
            <span className="material-icons">close</span>
          </div>
        </div>
        <div className="border-b border-gray pb-4 mb-4">
          <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm font-bold">
            <div className="w-full shrink-none">
              SHIPMENT ID - TA{quote?.shipment?.mvxid}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Issue Date</div>
            <div className="w-1/2 shrink-none">
              <Moment format="ddd DD MMM, YYYY">{quote?.createdAt}</Moment>
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Due Date</div>
            <div className="w-1/2 shrink-none">
              <Moment format="ddd DD MMM, YYYY">{quote?.dueDate}</Moment>
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Mode</div>
            <div className="w-1/2 shrink-none">
              {quote?.shipment?.serviceMode === "air" && (
                <Air className="h-[15px] w-auto" />
              )}
              {quote?.shipment?.serviceMode === "land" && (
                <Land className="h-[15px] w-auto" />
              )}
              {quote?.shipment?.serviceMode === "ocean" && (
                <Sea className="h-[17px] w-auto" />
              )}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Status</div>
            <div className="w-1/2 shrink-none">
              <Status className={`select-none capitalize ${quote?.status}`}>
                {quote?.status}
              </Status>
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Freight Forwarder</div>
            <div className="w-1/2 shrink-none">
              {quote?.shipment?.freightForwarder?.profile?.businessName ||
                quote?.shipment?.freightForwarder?.fullName ||
                "--"}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Bill From</div>
            <div className="w-1/2 shrink-none">
              {quote?.creator?.profile?.businessName ||
                quote?.creator?.fullName ||
                "--"}
              {quote?.creator?.profile?.businessAddress?.address ? (
                <>
                  <br />
                  {quote?.creator?.profile?.businessAddress?.address}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Bill To</div>
            <div className="w-1/2 shrink-none">
              {quote?.quoteType === "ff" ? (
                <>
                  {quote?.freightForwarder?.profile?.businessName ||
                    quote?.freightForwarder?.fullName ||
                    "--"}
                  {quote?.freightForwarder?.profile?.businessAddress
                    ?.address ? (
                    <>
                      <br />
                      {
                        quote?.freightForwarder?.profile?.businessAddress
                          ?.address
                      }
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {quote?.shipment?.shipperDetails?.fullName}
                  {quote?.shipment?.shipperDetails?.address?.address ? (
                    <>
                      <br />
                      {quote?.shipment?.shipperDetails?.address.address}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">PoL</div>
            <div className="w-1/2 shrink-none">
              {quote?.shipment?.portOfLoading?.address || "---"}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Origin pickup</div>
            <div className="w-1/2 shrink-none">
              {quote?.shipment?.origin?.address}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Destination</div>
            <div className="w-1/2 shrink-none">
              {quote?.shipment?.destination?.address}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Gross Weight</div>
            <div className="w-1/2 shrink-none">
              {quote?.grossWeight?.kg ? `${quote?.grossWeight?.kg} kg` : "---"}
            </div>
          </div>
        </div>
        {quote?.importCharges?.length > 0 && (
          <div className="border-b border-gray pb-4 mb-4">
            <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm font-bold">
              <div className="w-3/4 shrink-none">IMPORT CHARGES</div>
              <div className="w-1/4 shrink-none">AMOUNT</div>
            </div>
            {quote?.importCharges.map((q, index) => (
              <div
                key={index}
                className="px-6 py-2 flex text-sm text-[#142837]"
              >
                <div className="w-3/4 shrink-none capitalize">{q.name}</div>
                <div className="w-1/4 shrink-none">{`${
                  q.currency
                } ${formatCurrency(q.price)}`}</div>
              </div>
            ))}
            <div className="px-6 py-2 flex text-sm text-[#000] font-medium justify-end">
              <div className="w-1/2 shrink-none border-y py-4 border-[#16C6A4]">
                <div className="w-full shrink-none flex mb-2">
                  <div className="w-1/2 shrink-none pl-4">Sub Total</div>
                  <div className="w-1/2 shrink-none">
                    {quote?.importCharges[0]?.currency
                      ? quote?.importCharges[0]?.currency
                      : ""}{" "}
                    {formatCurrency(
                      quote?.importCharges.reduce(
                        (acc, curr) => acc + curr.price,
                        0
                      )
                    )}
                  </div>
                </div>
                {quote?.importCharges?.some((q) => q.vat === true) && (
                  <div className="w-full shrink-none flex">
                    <div className="w-1/2 shrink-none pl-4">VAT (7.5%)</div>
                    <div className="w-1/2 shrink-none">
                      {
                        quote?.importCharges?.filter((q) => q.vat === true)?.[0]
                          .currency
                      }{" "}
                      {formatCurrency(
                        quote?.importCharges
                          ?.filter((q) => q.vat === true)
                          ?.reduce(
                            (acc, curr) => acc + (curr.price * 7.5) / 100,
                            0
                          )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {quote?.exportCharges?.length > 0 && (
          <div className="border-b border-gray pb-4 mb-4">
            <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm font-bold">
              <div className="w-3/4 shrink-none">IMPORT CHARGES</div>
              <div className="w-1/4 shrink-none">AMOUNT</div>
            </div>
            {quote?.exportCharges.map((q, index) => (
              <div
                key={index}
                className="px-6 py-2 flex text-sm text-[#142837]"
              >
                <div className="w-3/4 shrink-none capitalize">{q.name}</div>
                <div className="w-1/4 shrink-none">{`${
                  q.currency
                } ${formatCurrency(q.price)}`}</div>
              </div>
            ))}
            <div className="px-6 py-2 flex text-sm text-[#000] font-medium justify-end">
              <div className="w-1/2 shrink-none border-y py-4 border-[#16C6A4]">
                <div className="w-full shrink-none flex mb-2">
                  <div className="w-1/2 shrink-none pl-4">Sub Total</div>
                  <div className="w-1/2 shrink-none">
                    {quote?.exportCharges[0]?.currency
                      ? quote?.exportCharges[0]?.currency
                      : ""}{" "}
                    {formatCurrency(
                      quote?.exportCharges.reduce(
                        (acc, curr) => acc + curr.price,
                        0
                      )
                    )}
                  </div>
                </div>
                {quote?.exportCharges?.some((q) => q.vat === true) && (
                  <div className="w-full shrink-none flex">
                    <div className="w-1/2 shrink-none pl-4">VAT (7.5%)</div>
                    <div className="w-1/2 shrink-none">
                      {
                        quote?.exportCharges?.filter((q) => q.vat === true)?.[0]
                          .currency
                      }{" "}
                      {formatCurrency(
                        quote?.exportCharges
                          ?.filter((q) => q.vat === true)
                          ?.reduce(
                            (acc, curr) => acc + (curr.price * 7.5) / 100,
                            0
                          )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {quote?.exwCharges?.length > 0 && (
          <div className="border-b border-gray pb-4 mb-4">
            <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm font-bold">
              <div className="w-3/4 shrink-none">IMPORT CHARGES</div>
              <div className="w-1/4 shrink-none">AMOUNT</div>
            </div>
            {quote?.exwCharges.map((q, index) => (
              <div
                key={index}
                className="px-6 py-2 flex text-sm text-[#142837]"
              >
                <div className="w-3/4 shrink-none capitalize">{q.name}</div>
                <div className="w-1/4 shrink-none">{`${
                  q.currency
                } ${formatCurrency(q.price)}`}</div>
              </div>
            ))}
            <div className="px-6 py-2 flex text-sm text-[#000] font-medium justify-end">
              <div className="w-1/2 shrink-none border-y py-4 border-[#16C6A4]">
                <div className="w-full shrink-none flex mb-2">
                  <div className="w-1/2 shrink-none pl-4">Sub Total</div>
                  <div className="w-1/2 shrink-none">
                    {quote?.exwCharges[0]?.currency
                      ? quote?.exwCharges[0]?.currency
                      : ""}{" "}
                    {formatCurrency(
                      quote?.exwCharges.reduce(
                        (acc, curr) => acc + curr.price,
                        0
                      )
                    )}
                  </div>
                </div>
                {quote?.exwCharges?.some((q) => q.vat === true) && (
                  <div className="w-full shrink-none flex">
                    <div className="w-1/2 shrink-none pl-4">VAT (7.5%)</div>
                    <div className="w-1/2 shrink-none">
                      {
                        quote?.exwCharges?.filter((q) => q.vat === true)?.[0]
                          .currency
                      }{" "}
                      {formatCurrency(
                        quote?.exwCharges
                          ?.filter((q) => q.vat === true)
                          ?.reduce(
                            (acc, curr) => acc + (curr.price * 7.5) / 100,
                            0
                          )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {quote?.customCharges?.length > 0 && (
          <div className="border-b border-gray pb-4 mb-4">
            <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm font-bold">
              <div className="w-3/4 shrink-none">IMPORT CHARGES</div>
              <div className="w-1/4 shrink-none">AMOUNT</div>
            </div>
            {quote?.customCharges.map((q, index) => (
              <div
                key={index}
                className="px-6 py-2 flex text-sm text-[#142837]"
              >
                <div className="w-3/4 shrink-none capitalize">{q.name}</div>
                <div className="w-1/4 shrink-none">{`${
                  q.currency
                } ${formatCurrency(q.price)}`}</div>
              </div>
            ))}
            <div className="px-6 py-2 flex text-sm text-[#000] font-medium justify-end">
              <div className="w-1/2 shrink-none border-y py-4 border-[#16C6A4]">
                <div className="w-full shrink-none flex mb-2">
                  <div className="w-1/2 shrink-none pl-4">Sub Total</div>
                  <div className="w-1/2 shrink-none">
                    {quote?.customCharges[0]?.currency
                      ? quote?.customCharges[0]?.currency
                      : ""}{" "}
                    {formatCurrency(
                      quote?.customCharges.reduce(
                        (acc, curr) => acc + curr.price,
                        0
                      )
                    )}
                  </div>
                </div>
                {quote?.customCharges?.some((q) => q.vat === true) && (
                  <div className="w-full shrink-none flex">
                    <div className="w-1/2 shrink-none pl-4">VAT (7.5%)</div>
                    <div className="w-1/2 shrink-none">
                      {
                        quote?.customCharges?.filter((q) => q.vat === true)?.[0]
                          .currency
                      }{" "}
                      {formatCurrency(
                        quote?.customCharges
                          ?.filter((q) => q.vat === true)
                          ?.reduce(
                            (acc, curr) => acc + (curr.price * 7.5) / 100,
                            0
                          )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="w-full flex justify-center flex font-bold mt-6 pb-5 border-b border-gray mb-6">
          {Object.entries(
            getTotal([
              quote?.importCharges,
              quote?.exportCharges,
              quote?.exwCharges,
              quote?.customCharges,
            ])
          )?.map((charge, index) => (
            <div key={index} className="mr-12">
              <p className="text-sm font-medium">
                Grand Total in ({charge[0]})
              </p>
              <p className="text-xl">{`${charge[0]} ${formatCurrency(charge[1], 2)}`}</p>
            </div>
          ))}
        </div>
        <div className="w-full flex justify-center">
          <button
            type="submit"
            className="bg-[#16C6A4] px-8 h-[40px] rounded-lg text-white mb-8 relative flex items-center"
            onClick={() =>
              downloadURLFile(quote?.file?.path, quote?.file?.fileName)
            }
          >
            <span className="material-icons mr-[10px]">arrow_downward</span>
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

function getTotal(toParse) {
  let parsed = {};

  toParse.forEach((charge) => {
    charge?.length &&
      charge.forEach((lineItem) => {
        if (parsed[lineItem.currency]) {
          parsed[lineItem.currency] += lineItem.price;
        } else {
          parsed[lineItem.currency] = lineItem.price;
        }

        if (lineItem.vat) {
          parsed[lineItem.currency] += (lineItem.price * 7.5) / 100;
        }
      });
  });

  return parsed;
}

const Status = styled.p`
  &.active {
    color: #1b2bbc;
  }
  &.pending {
    color: #b417cd;
  }
  &.cancelled {
    color: #e35328;
  }
  &.delivered {
    color: #16c6a4;
  }
`;

export default QuoteDetails;
