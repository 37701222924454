export const rateConstants = {
  FETCH_RATES_REQUEST: "FETCH_RATES_REQUEST",
  FETCH_RATES_SUCCESS: "FETCH_RATES_SUCCESS",
  FETCH_RATES_ERROR: "FETCH_RATES_ERROR",
  SEARCH_PORT_REQUEST: "SEARCH_PORT_REQUEST",
  SEARCH_PORT_SUCCESS: "SEARCH_PORT_SUCCESS",
  SEARCH_PORT_ERROR: "SEARCH_PORT_ERROR",
  SEARCH_PRODUCTS_REQUEST: "SEARCH_PRODUCTS_REQUEST",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_ERROR: "SEARCH_PRODUCTS_ERROR",
  CREATE_RATE_REQUEST: "CREATE_RATE_REQUEST",
  CREATE_RATE_SUCCESS: "CREATE_RATE_SUCCESS",
  CREATE_RATE_ERROR: "CREATE_RATE_ERROR",
  CLEAR_RATE: "CLEAR_RATE",
};
