const data = () => {
  const envData = {
    googleAPIKey: process.env.REACT_APP_GOOGLE_API_KEY,
    currentInstance: process.env.REACT_APP_INSTANCE,
  };

  if (process.env.REACT_APP_INSTANCE === "development") {
    return {
      ...envData,
      tradeAllyURL: process.env.REACT_APP_DEVELOPMENT_TRADEALLY_URL,
    };
  } else if (process.env.REACT_APP_INSTANCE === "staging") {
    return {
      ...envData,
      tradeAllyURL: process.env.REACT_APP_STAGING_TRADEALLY_URL,
    };
  } else if (process.env.REACT_APP_INSTANCE === "production") {
    return {
      ...envData,
      tradeAllyURL: process.env.REACT_APP_PROD_TRADEALLY_URL,
    };
  }
};

export const currentENVData = { ...data() };
