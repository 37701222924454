import { currencySign, formatCurrency, getCountryfromLatLng } from "helpers";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import styled from "styled-components";

const User = ({ map, user, setSelectedShipment, setModal }) => {
  const [country, setCountry] = useState("");

  const getGeoLocation = () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      let country = await getCountryfromLatLng({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });

      setCountry(
        country.details?.find((c) => c.types.includes("country"))?.long_name
      );
    });
  };

  useEffect(() => {
    if (map) {
      getGeoLocation();
    }
  }, [map]);

  return (
    <div className="w-full text-[#142837] cursor-pointer duration-300 hover:bg-[#ECFAF8] text-sm px-6 py-4 bg-white rounded-md flex mb-2 items-center">
      <div className="w-[10%] shrink-0">
        {user?.profile?.businessNumber || "--"}
      </div>
      <div className="w-[25%] shrink-0 pr-2">
        {user?.profile?.businessName || user?.fullName || "--"}
      </div>
      <div className="w-[15%] shrink-0">
        <Moment format="ddd, DD/MM/YYYY">{user?.createdAt}</Moment>
      </div>
      <div className="w-[15%] shrink-0">
        <Status
          className={`text-[#16C6A4] max-w-fit rounded-md select-none capitalize font-medium text-sm ${
            user?.active ? "active" : "inactive"
          }`}
        >
          {user?.active ? "Active" : "Inactive"}
        </Status>
      </div>
      <div className="w-[15%] shrink-0 capitalize">
        {user?.profile?.businessAddress?.address ? country : "--"}
      </div>
      <div className="w-[15%] shrink-0">
        <div className="px-2 py-1 bg-[#D9D9D9]/[0.25] border border-[#16C6A4] text-[#16C6A4] max-w-fit">
          {user?.totalIncome?.currency
            ? `${currencySign(user?.totalIncome?.currency)}${formatCurrency(
                user?.totalIncome?.value
              )}`
            : ""}
        </div>
      </div>
      <div className="w-[5%] shrink-0 flex justify-center">
        <div className="group h-[50px] relative">
          <div className="h-[40px] w-[40px] rounded flex justify-between items-center select-none cursor-pointer text-[#142837]">
            <span className="material-icons ml-auto">more_horiz</span>
          </div>
          <div className="border border-gray bg-white group-hover:block hidden mt-2 px-4 py-2 text-sm rounded select-none absolute -right-3 z-30">
            {/* <div className="border border-gray bg-white block mt-2 px-4 py-2 text-sm rounded select-none absolute right-0 z-30"> */}
            <div
              onClick={() => {
                setModal("view-details");
                setSelectedShipment(user._id);
              }}
              className="flex hover:font-medium hover:text-black mt-1 mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap"
            >
              View Details
            </div>
            <div className="flex mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap">
              Contact User
            </div>
            <div className="flex mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap">
              Change Status
            </div>
            <div className="flex mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap">
              Delete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Status = styled.div`
  &.inactive {
    color: #e35328;
  }
  &.active {
    color: #16c6a4;
  }
`;

export default User;
