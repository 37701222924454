import React, { useEffect } from "react";
import RateList from "./List";
import { useDispatch, useSelector } from "react-redux";
import { rateActions } from "actions/rateActions";
import Moment from "react-moment";

const Rate = () => {
  const dispatch = useDispatch();
  const rate = useSelector((state) => state.rate);

  useEffect(() => {
    if (rate?.ratesLoading === false) {
      dispatch(rateActions.getRates({}, true));
    }
    // eslint-disable-next-line
  }, []);

  const fetchData = (page) => {
    if (rate?.ratesLoading === false) {
      dispatch(rateActions.getRates({ page }));
    }
  };

  return (
    <>
      <div className="p-8 pb-0 flex justify-between">
        <div className="p-2 bg-white max-w-fit rounded-lg mb-8">
          <div className="h-[35px] bg-[#16C6A4] text-white flex items-center px-8">
            Overview
          </div>
        </div>
        <div className="text-right">
          <p className="text-xs">Today</p>
          <p className="font-bold">
            <Moment format="MMMM DD, YYYY"></Moment>
          </p>
        </div>
      </div>
      <RateList fetchData={fetchData} />
    </>
  );
};

export default Rate;
