import React from "react";
import styled from "styled-components";
import { ReactComponent as ChatIcon } from "assets/images/dashboard-icons/chat.svg";
import { getColor, getInitials } from "helpers";

const Admin = ({ user }) => {
  const userName =
    user.details?.profile?.businessName || user.details?.fullName || "--";

  return (
    <div
      className={`py-4 px-4 bg-white mr-[20px] mb-[20px] shrink-0 rounded-md drop-shadow relative z-10 w-[calc((100%-40px)/3)]`}
    >
      <div className="flex items-center mb-4">
        {user.details?.profile?.logo ? (
          <Passport
            url={user.details?.profile?.logo}
            className="w-12 h-12 shrink-0 rounded-full bg-cover bg-center mr-4 border border-gray"
          ></Passport>
        ) : (
          <PassportHolder
            bg={getColor(userName)}
            className="w-12 h-12 shrink-0 rounded-full bg-cover bg-center mr-4 flex items-center justify-center"
          >
            <span className="text-white">{getInitials(userName)}</span>
          </PassportHolder>
        )}
        <div>
          <p className="font-medium text-xl">{userName}</p>
          <p className="text-sm">{user?.details?.profile?.businessType}</p>
        </div>
      </div>
      <div className="flex items-center justify-between ml-16 mb-8">
        <span className="text-[#6B778C] text-xs">
          {user.details?.profile?.email || user.details?.email || "--"}
        </span>
        <div className="w-6 h-6 rounded-full bg-[#16C6A4]/25 flex justify-center items-center">
          <ChatIcon className="h-3 w-auto" />
        </div>
      </div>
      <p className="text-xs text-[#142837] mb-2">Task</p>
      <div className="h-[4px] w-full bg-[#D9D9D9] rounded mb-6">
        <div
          className={`w-[40%] h-full flex items-center justify-end ${
            user?.details?.progress > 50 ? "bg-[#16C6A4]" : "bg-[#E35328]"
          }`}
        >
          <div className="w-4 h-4 bg-white rounded-full drop-shadow"></div>
        </div>
      </div>
      <p className="text-xs text-[#142837] mb-2">Teams</p>
      <div className="flex justify-between relative z-10">
        <div className="flex items-center">
          <div className="flex mr-2">
            {user?.freightForwarders?.length > 0 ? (
              user?.freightForwarders?.slice(0, 4)?.map((u, i) =>
                u?.profile?.logo ? (
                  <Passport
                    url={u?.profile?.logo}
                    className={`w-12 h-12 shrink-0 rounded-full bg-cover bg-center border border-gray ${
                      i !== 0 && "-ml-6"
                    }`}
                  ></Passport>
                ) : (
                  <PassportHolder
                    bg={getColor(
                      u?.profile?.businessName || u?.fullName || "--"
                    )}
                    className={`w-12 h-12 shrink-0 rounded-full bg-cover bg-center flex items-center justify-center ${getColor(
                      u?.profile?.businessName || u?.fullName || "--"
                    )} ${i !== 0 && "-ml-6"}`}
                  >
                    <span className="text-white">
                      {getInitials(
                        u?.profile?.businessName || u?.fullName || "--"
                      )}
                    </span>
                  </PassportHolder>
                )
              )
            ) : (
              <i className="text-[#ACACAC] text-sm">--</i>
            )}
          </div>
          {user?.freightForwarders?.length > 4 && (
            <span className="text-[#6B778C] text-sm font-medium">
              +{user?.freightForwarders?.length - 4}
            </span>
          )}
        </div>
        <div className="h-[30px] pt-3 relative z-10">
          <div className="flex items-center cursor-pointer select-none">
            <p className="text-sm text-[#ACACAC]">Expore Teams</p>
            <span class="material-icons -mr-2 text-[#16C6A4]">
              chevron_right
            </span>
          </div>
          <div className="border border-gray mt-2 w-full p-8 rounded bg-black"></div>
        </div>
      </div>
    </div>
  );
};

const Passport = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
`;

const PassportHolder = styled.div`
  background-color: ${(props) => props.bg};
`;

export default Admin;
