import { currencySign } from "helpers";
import { capitalizeFirstCharacter } from "helpers/capitalizeFirstCharacter";
import React, { useEffect } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "helpers";
import { useNavigate } from "react-router-dom";
import { rateActions } from "actions";

const Review = ({ rate, setStep, setModal }) => {
  const dispatch = useDispatch();
  const rateSelector = useSelector((state) => state.rate);
  const ratePrevious = usePrevious(rateSelector.creatingRate);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(rateActions.clear());
    };
    
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      ratePrevious === true &&
      rateSelector.creatingRate === false &&
      rateSelector.rate.data
    ) {
      navigate("/dashboard/rates");
    }

    // eslint-disable-next-line
  }, [rateSelector]);

  return (
    <div className="p-10 relative">
      <button
        type="button"
        onClick={() => setStep(1)}
        className="bg-white select-none border border-gray w-fit h-[40px] px-6 rounded-md text-gray text-sm flex items-center absolute right-0 top-0"
      >
        <span className="material-icons mr-3 text-lg">edit</span>
        Edit
      </button>
      {rate.charges?.map((r, index) => (
        <div key={index} className="w-full border-b border-gray pb-5 mb-5">
          <p className="font-medium mb-3">{r.name}</p>
          {r.charges?.map((charge, chargeIndex) => (
            <div key={chargeIndex} className="flex mb-4 text-sm">
              <div className="w-full">{charge.description}</div>
              <div className="w-[35%] text-[#16C6A4] font-medium">
                {currencySign(charge.currency)} {charge.amount}
              </div>
            </div>
          ))}
        </div>
      ))}
      <div className="w-full border-b border-gray pb-5 mb-5">
        <p className="font-medium mb-3">Additional Information</p>
        <div className="flex mb-4 text-sm">
          <div className="w-full">Route</div>
          <div className="w-[35%] font-medium">1 May, 2023</div>
        </div>
        <div className="flex mb-4 text-sm">
          <div className="w-full">Effective Start date</div>
          <div className="w-[35%] font-medium">
            <Moment format="DD-MM-YYYY">{rate?.validity?.from}</Moment>
          </div>
        </div>
        <div className="flex mb-4 text-sm">
          <div className="w-full">Effective End date</div>
          <div className="w-[35%] font-medium">
            <Moment format="DD-MM-YYYY">{rate?.validity?.to}</Moment>
          </div>
        </div>
        <div className="flex mb-4 text-sm">
          <div className="w-full">Movement type</div>
          <div className="w-[35%] font-medium">
            {rate?.movementMode === "land" && "Land freight"}
            {rate?.movementMode === "ocean" && "Sea freight"}
            {rate?.movementMode === "air" && "Air freight"}
          </div>
        </div>
        <div className="flex mb-4 text-sm">
          <div className="w-full">Load type</div>
          <div className="w-[35%] font-medium">
            {["20", "40"].includes(rate?.loadType)
              ? `${rate?.loadType} ft container`
              : capitalizeFirstCharacter(rate?.loadType)}
          </div>
        </div>
        <div className="flex mb-4 text-sm">
          <div className="w-full">Currency</div>
          <div className="w-[35%] font-medium">1 May, 2023</div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center border-b border-gray pb-10 pt-5">
        <p className="font-bold">Total cost</p>
        <p className="text-xl text-[#16C6A4] font-bold">$37,500.00 USD</p>
      </div>

      <div className="flex justify-center select-none mt-10">
        <div className="w-[45%] shrink-0">
          <button
            type="button"
            onClick={() => setModal("create-rate")}
            className="bg-[#16C6A4] border border-[#16C6A4] w-full h-[50px] rounded-md text-white relative"
          >
            Publish Rate
          </button>
        </div>
      </div>
    </div>
  );
};

export default Review;
