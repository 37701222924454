import React from "react";
import Loading from "./Loading";
import Tooltip from "react-simple-tooltip";
import { ReactComponent as IncreaseIcon } from "assets/images/dashboard-icons/increase.svg";

const Analytics = ({
  title,
  value,
  valueRaw,
  image,
  loading,
  sub,
  increase,
  currency,
  border,
  half,
}) => {
  return (
    <div
      className={`border hover:border-[#77ACF1] duration-300 py-4 px-6 bg-white mr-[20px] mb-[20px] shrink-0 rounded-md drop-shadow ${
        half ? "w-[calc((100%-20px)/2)]" : "w-[calc((100%-40px)/3)]"
      } ${border ? border : "border-white"}`}
    >
      {title ? (
        <div className="h-[40px] text-sm font-medium text-[#6B778C]">
          {title}
        </div>
      ) : (
        ""
      )}
      <div
        className={`flex justify-between items-center ${currency && "mb-8"}`}
      >
        {loading ? (
          <div className="relative w-[30px] h-[30px] flex items-center justify-center">
            <Loading size={25} color="black" />
          </div>
        ) : valueRaw ? (
          <Tooltip content={valueRaw}>
            <p className="font-bold text-2xl text-[#3F3D56]">{value}</p>
          </Tooltip>
        ) : (
          <p className="font-bold text-2xl text-[#3F3D56]">{value}</p>
        )}
        {image ? (
          image
        ) : (
          <span className="text-[#6B778C] font-bold">{currency}</span>
        )}
      </div>
      {sub?.title && (
        <div className="flex justify-between items-center mt-2">
          <p className="text-xs text-[#3F3D56]">{sub?.title}</p>
          <p className="text-xs font-medium">{sub?.value}</p>
        </div>
      )}
      {increase && (
        <div className="flex items-center mt-2">
          <p className="text-xs text-[#16C6A4] mr-10 font-medium">
            {increase} increase
          </p>
          <IncreaseIcon className="h-[10px] w-auto" />
        </div>
      )}
    </div>
  );
};

export default Analytics;
