import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as ChartIcon } from "assets/images/dashboard-icons/chart-3.svg";
import { ReactComponent as Select } from "assets/images/dashboard-icons/select-dropdown.svg";
import { ReactComponent as Check } from "assets/images/dashboard-icons/check.svg";
import Quote from "./Quote";
import { useSelector } from "react-redux";
import ModalContainer from "components/common/ModalContainer";
import QuoteDetails from "./QuoteDetails";
import Loading from "components/common/Loading";
import { usePrevious } from "helpers";

const QuoteList = ({ fetchData }) => {
  const quote = useSelector((state) => state.quote);
  const [isVisible, setIsVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const prevIsVisible = usePrevious(isVisible);

  const observerTarget = useRef(null);

  const callbackFunction = useCallback((entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    if (prevIsVisible === false && isVisible === true) {
      fetchData(quote?.quotes?.pagination?.next);
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      threshold: 1,
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line
        observer.unobserve(observerTarget.current);
      }
    };
    // eslint-disable-next-line
  }, [observerTarget]);

  return (
    <>
      {modal !== false ? (
        <ModalContainer closeModal={() => setModal(false)}>
          {modal === "view-details" ? (
            <QuoteDetails
              selectedQuote={
                selectedQuote &&
                quote?.quotes?.data?.filter((q) => q._id === selectedQuote)?.[0]
              }
              setModal={setModal}
            />
          ) : (
            ""
          )}
        </ModalContainer>
      ) : (
        ""
      )}
      <div className="p-8">
        <div className="flex mb-8 relative">
          <div className="h-[40px] w-[40px] rounded-l mr-2 bg-[#16C6A4] flex items-center justify-center">
            <ChartIcon className="h-[10px] w-auto" />
          </div>
          <div className="h-[40px] px-6 pr-3 flex bg-white mr-2">
            <div className="flex items-center mr-4">
              <div className="bg-[#1B2BBC]/20 rounded px-2 py-0.5 text-sm">
                {quote?.quotes?.data?.length}
              </div>
            </div>
            <div className="group h-[50px]">
              <div className="h-[40px] w-[150px] rounded flex justify-between select-none cursor-pointer text-[#142837]">
                <div className="pl-4 flex items-center text-sm">All</div>
                <div className="h-[40px] w-[40px] flex items-center justify-center">
                  <Select />
                </div>
              </div>
              <div className="border border-gray bg-white group-hover:block hidden mt-2 px-4 py-2 text-sm rounded select-none">
                <p className="text-sm pb-2 mb-3 border-b border-gray">
                  <span className="text-[#E35328]">Filter by:</span>{" "}
                  <span className="text-[#6B778C]">Status</span>
                </p>
                <div className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                  Accepted
                </div>
                <div className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                  Rejected
                </div>
                <div className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer">
                  Pending
                </div>
              </div>
            </div>
          </div>
          <div className="h-[40px] px-4 pr-6 mr-2 flex bg-white">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              withPortal
              placeholderText="Choose date range"
            />
          </div>
          <div className="h-[40px] w-[40px] rounded-r bg-[#16C6A4] flex items-center justify-center">
            <Check className="h-[15px] w-auto cursor-pointer" />
          </div>
        </div>
        <div className="w-full overflow-x-auto">
          <div className="w-full h-[50px] text-white text-sm px-6 bg-[#16C6A4] rounded-md flex items-center mb-2">
            <div className="w-[10%] shrink-0">Shipment ID</div>
            <div className="w-[25%] shrink-0">Freight forwarder</div>
            <div className="w-[15%] shrink-0">Date Created</div>
            <div className="w-[15%] shrink-0">Quote Type</div>
            <div className="w-[15%] shrink-0">Response Time</div>
            <div className="w-[15%] shrink-0">Status</div>
            <div className="w-[5%] shrink-0"></div>
          </div>

          {quote?.quotes?.data ? (
            <>
              {quote?.quotes?.data.map((q, index) => (
                <React.Fragment key={index}>
                  <Quote
                    quote={q}
                    setModal={setModal}
                    setSelectedQuote={setSelectedQuote}
                  />
                </React.Fragment>
              ))}
              <div className="relative w-full h-[50px] flex items-center justify-center mt-8">
                {quote?.quotesLoading && (
                  <Loading size={30} color="black m-auto" />
                )}
              </div>
            </>
          ) : (
            <p className="w-full text-center pt-10">Loading...</p>
          )}
          <div ref={observerTarget}></div>
        </div>
      </div>
    </>
  );
};

export default QuoteList;
