import { formatCurrency } from "./currency";

export function truncateNumber(amount) {
  if (amount > 999999999999999999) {
    return `${formatCurrency(amount / 1000000000000, 2)} t`;
  } else if (amount > 999999999999999) {
    return `${formatCurrency(amount / 1000000000)} b`;
  } else if (amount > 999999999999) {
    return `${formatCurrency(amount / 1000000)} m`;
  } else if (amount > 999999999) {
    return `${formatCurrency(amount / 1000)} k`;
  }

  return formatCurrency(amount, 2);
}
